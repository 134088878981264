import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { login } from './authSlice';
import { Button, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Snackbar, Alert, useTheme } from '@mui/material';
import Spinner from '../spinner/Spinner';
import {ReactComponent as Logo} from '../../assets/Logo.svg';

export const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="text.secondary" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://cvcconstruction.com/">
        Concrete Value Corp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated, isLoading, isError, isSuccess, message } = useAppSelector(state => state.auth);
  const {isMobile} = useAppSelector((state:any) => state.layout);

  const theme = useTheme();

  useEffect(() => {
    if (isAuthenticated && isSuccess && !isLoading) {
      navigate('/');
    }

  }, [isAuthenticated, isError, isSuccess, message]);

  if (isLoading) {
    return <Spinner />;
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(login({ username: formData.username, password: formData.password }));
  };

  return (
      <Grid container spacing={0}  sx={{display: "flex", justifyContent: "center", alignItems: "center", height: '100vh', width: "100vw", maxHeight: '1200px'}}>
        <Grid item xs={12} sm={6} 
        sx={{
          height: (isMobile) ? "40%" : "100%",
          width: (isMobile) ? "40%" : "100%",
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          p: 4,
        }}>
          <Box 
          sx={{
            height: "auto", 
            width: "100%",  
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            marginBottom: (!isMobile) ? 18 : 2
          }}>
            <Logo 
              style={{
                fill: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main, 
                maxWidth: '600px', 
                maxHeight: '360px' 
              }} 
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} sx={{
          height: (isMobile) ? "60%" : "100%", 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: (isMobile) ? 'center' : 'flex-start',    
          p: 4
        }}>
          <Typography component="h1" variant="h4" sx={{textAlign: "center", mb: 2, fontWeight: "400" }}>
            Sign In
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 400 }}>
            <TextField
              size='small'
              margin="dense"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={formData.username}
              onChange={onChange}
              autoComplete="username"
              autoFocus
            />
            <TextField
              size='small'
              margin="dense"
              required
              fullWidth
              name="password"
              label="Password"
              value={formData.password}
              onChange={onChange}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/password-reset" variant="body2">
                  Reset password
                </Link>
              </Grid>

            </Grid>
            <Copyright sx={{textAlign: "center", m: 2, width: '100%' }} />
          </Box>
          
        </Grid>
      </Grid>
  );
};

export default Login;
