import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import Layout from './features/layout/Layout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAppDispatch } from './app/hooks';
import { useSelector } from 'react-redux';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || "");

function App() {
  const {themeMode, themeConfig} = useSelector((state:any) => state.layout);
  const [theme, setTheme] = useState<any>({});

  useEffect(() => {
    if(themeConfig){
      setTheme(
      createTheme({
        ...themeConfig,
        palette: {
          ...themeConfig.palette,
          mode: themeMode, // Dynamically set the theme mode
        },
    }))}   
  },[themeConfig, themeMode]);

  return (
    <>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <Layout />
          </ThemeProvider>
        </LocalizationProvider>
      </Router>
    </>
  );
}

export default App;
