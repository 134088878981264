import { Autocomplete, Box, Button, Modal, Popper, TextField, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useParams } from "react-router-dom";
import React from "react";
import { useGetBudgetRevisionsQuery, useGetContractNumbersQuery, useGetProposalRevisionsQuery } from "../../dropdowns/dropdownApi";

const SubjobDetailColumns: GridColDef[] = [
    {
        field: 'lot_number',
        headerName: 'Lot #',
        width: 120,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: true
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: true
    }  
];

const useSubjobItemColumns = ():GridColDef[] => {
    const dispatch = useAppDispatch();
    const { selectedItem } = useAppSelector((state) => state.api);
    const {project_id} = useParams();
    const [notes, setNotes] = useState<any>([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event:any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen((prev) => !prev);
        console.log(open)
    };

    const handleAddNote = (params:any, newNote:string) => {
        // Add a new note to the identifying_notes array and update the grid's data source
        const newData = params.api.getRow(params.id);
        newData.notes.push(newNote);
        params.api.updateRows([newData]);
      };

  
    const columns = useMemo(() => {
      return [
        {
            field: 'plan',
            headerName: 'Plan',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'elevation',
            headerName: 'Elevation',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option',
            headerName: 'Option',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option_code',
            headerName: 'Option Code',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'quantity',
            headerName: 'Site Quantity',
            width: 160,
            type: 'number',
            sortable: true,
            editable: true,
            headerAlignt: 'left'
        },
        {
            field: 'notes',
            headerName: 'Notes',
            width: 160,
            type: 'string',
            sortable: true,
            editable: true,
            renderCell: (params:any) => (
                <React.Fragment>
                  <Button onClick={handleClick}>Notes</Button>
                  <Modal open={open} >
                    <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                      {params?.value ? params?.value?.map((note:string, index:number) => (
                        <Typography key={index}>{note}</Typography>
                      ))
                    :
                        <Typography>Test</Typography>
                    }
                    </Box>
                  </Modal>
                </React.Fragment>
            ),
            renderEditCell: (params:any) => (
                <React.Fragment>
                  <Button onClick={handleClick}>Notes</Button>
                  <Modal open={open} sx={{width: "100vw", height: "800px"}}>
                    <>
                        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                        {params?.value?.map((note:string, index:number) => (
                            <Typography key={index}>{note}</Typography>
                        ))}
                        </Box>
                        <TextField
                            label="Add Note"
                            variant="standard"
                            onKeyDown={(e:any) => {
                            if (e.key === 'Enter' && e.target.value) {
                                handleAddNote(params, e.target.value);
                                console.log("here")
                                e.target.value = ''; // Clear input after adding
                            }
                            }}
                        />
                    </>
                    
                  </Modal>
                </React.Fragment>
            ),
            
        },
      ];
    }, [open]);
  
    return columns;
  };

const ReleaseInfoColumns:GridColDef[] = [
    {
        field: 'lot_number',
        headerName: 'Lot #',
        width: 120,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: true
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: true
    },
    {
        field: 'customer_plan',
        headerName: 'Customer Plan',
        width: 220,
        type: 'string',
        sortable: true,
        editable: true
    },
    {
        field: 'customer_elevation',
        headerName: 'Customer Elevation',
        width: 220,
        type: 'string',
        sortable: true,
        editable: true
    },
    {
        field: 'garage_orientation',
        headerName: 'Orientation',
        width: 220,
        type: 'string',
        sortable: true,
        editable: true
    },
    {
        field: 'customer_release',
        headerName: 'Customer Release',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: true
    }    
]

const ReleaseInfoItemColumns:GridColDef[] = [
    {
        field: 'plan',
        headerName: 'Plan',
        width: 160,
        type: 'string',
        sortable: true,
        editable: true
    },

    {
        field: 'elevation',
        headerName: 'Elevation',
        width: 160,
        type: 'string',
        sortable: true,
        editable: true
    },
    {
        field: 'option',
        headerName: 'Option',
        width: 160,
        type: 'string',
        sortable: true,
        editable: true
    },
    {
        field: 'option_code',
        headerName: 'Option Code',
        width: 160,
        type: 'string',
        sortable: true,
        editable: true
    },
    
    {
        field: 'customer_option',
        headerName: 'Customer Option',
        width: 150,
        type: 'string',
        sortable: true,
        editable: true
    },
    {
        field: 'customer_option_code',
        headerName: 'Customer Option Code',
        width: 170,
        type: 'string',
        sortable: true,
        editable: true
    },
];

const CostsColumns:GridColDef[] = [
    {
        field: 'lot_number',
        headerName: 'Lot #',
        width: 120,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    },
    {
        field: 'total_budget_cost',
        headerName: 'Total Cost',
        width: 160,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
                ? `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`
                : '';
        },
    },
];

const useCostItemColumns = (props:any):GridColDef[] => {
    
    const {selectedItem} = useAppSelector((state:any) => state.api);
    const {project_id} = useParams();
    const params = useMemo(() => ({
        project_id: project_id || "", 
        job_type_id: selectedItem?.job_type_id?._id || ""
    }),[project_id, selectedItem]);
    const {data: budget_revision_numbers} = useGetBudgetRevisionsQuery(params, {
        skip: !params?.project_id || !params?.job_type_id
    });

    return ([
        {
            field: 'plan',
            headerName: 'Plan',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'elevation',
            headerName: 'Elevation',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option',
            headerName: 'Option',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option_code',
            headerName: 'Option Code',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'budget_id',
            headerName: 'Budget Revision #',
            width: 160,
            type: 'string',
            sortable: true,
            editable: true,
            valueGetter: (params:any) => {
                const budget = budget_revision_numbers?.data?.find((b:any) => {
                    return b?.value === params?.row?.budget_id
                });
                return budget?.label;

            },
            renderEditCell: (params: GridRenderCellParams<any, string>) => (
                <Autocomplete
                    value={params.value}
                    options={budget_revision_numbers?.data?.map((budget:any) => `${budget?.budget_name} - ${budget?.budget_rev_no}`) || []}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                        const budget = budget_revision_numbers?.data?.find((budget:any) => `${budget?.budget_name} - ${budget?.budget_rev_no}` === newValue);
                        params.api.setEditCellValue({ id: params.id, field: 'budget_id', value: budget?.value }, event);
                    }}
                    fullWidth />
            )
        },
        {
            field: 'budget_cost',
            headerName: 'Budget Cost',
            width: 160,
            type: 'number',
            headerAlign: 'right',
            sortable: true,
            editable: false,
            valueGetter: (params: any): string => {
                const value = params.value;
                return value !== undefined && value !== null
                    ? `$${value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`
                    : '';
            },
        },
    ]);
}

const ProposalPricingColumns:GridColDef[] =[
    {
        field: 'lot_number',
        headerName: 'Lot #',
        width: 120,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    }, 
    {
        field: 'total_proposal_price',
        headerName: 'Total Proposal Price',
        width: 160,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
                ? `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`
                : '';
        },
    },
    {
        field: 'gross_margin_proposal',
        headerName: 'GM ($)',
        width: 160,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
                ? `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`
                : '';
        },
    },
    {
        field: 'gross_margin_percent_proposal',
        headerName: 'GM (%)',
        width: 160,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const percentageValue = params.value / 100; // Divide by 100 to get the original percentage value
            return `${percentageValue.toLocaleString('en-US', {
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
        },
    },
];

const useProposalItemColumns = ():GridColDef[] => {
    const {project_id} = useParams();
    const {selectedItem} = useAppSelector((state:any) => state.api);
    const params = useMemo(() => ({
        project_id: project_id || "", 
        job_type_id: selectedItem?.job_type_id?._id || ""
    }),[project_id, selectedItem]);
    const {data: proposal_numbers} = useGetProposalRevisionsQuery(params, {
        skip: !params.project_id || !params.job_type_id
    });
    
    return ([
        {
            field: 'plan',
            headerName: 'Plan',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'elevation',
            headerName: 'Elevation',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option',
            headerName: 'Option',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option_code',
            headerName: 'Option Code',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        
        {
            field: 'proposal_id',
            headerName: 'Proposal ID',
            width: 160,
            type: 'string',
            sortable: true,
            editable: true,
            valueGetter: (params:any) => {
                const proposal = proposal_numbers?.data?.find((p:any) => {
                    return p?._id === params?.row?.proposal_id
                });
                return proposal?.proposal_rev_no;

            },
            renderEditCell: (params: GridRenderCellParams<any, string>) => (
                <Autocomplete
                    value={params.value}
                    isOptionEqualToValue={(option, value) => {
                        const proposal = proposal_numbers?.data?.find((p:any) => p.proposal_rev_no === option);
                        return proposal?._id === value;
                    }}
                    
                    options={proposal_numbers?.data?.map((proposal:any) => proposal?.proposal_rev_no) || []}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                        const proposal = proposal_numbers?.data?.find((proposal:any) => proposal?.proposal_rev_no === newValue);
                        params.api.setEditCellValue({ id: params.id, field: 'proposal_id', value: proposal?._id }, event);
                    }}
                    fullWidth />)
        },
        {
            field: 'proposal_price',
            headerName: 'Proposal Price',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false,
            valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
                ? `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`
                : '';
        },
        },
        {
            field: 'proposal_quantity',
            headerName: 'Proposal Quantity',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false
        },
    ]);
}

const ProposalContractComparisonColumns: GridColDef[] = [
    {
        field: 'lot_number',
        headerName: 'Lot #',
        width: 120,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    }, 
    {
        field: 'total_proposal_price',
        headerName: 'Total Proposal Price',
        type: 'number',
        width: 180,
        editable: false,
        valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
                ? `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`
                : '';
        },
    },
    {
        field: 'total_contract_price',
        headerName: 'Total Contract Price',
        type: 'number',
        width: 180,
        editable: false,
        valueGetter: (params: any): string => {
          const value = params.value;
          return value !== undefined && value !== null
            ? `$${value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`
            : '';
        },
        valueSetter: (params: any) => {
          const value = params.value;
          // Remove dollar sign and commas, then convert to a number
          const numberValue = value
            ? parseFloat(value.replace(/[^0-9.-]+/g, ''))
            : null;
          return { ...params.row, total_contract_price: numberValue };
        }
    },      
    {
        field: 'delta',
        headerName: 'Delta',
        type: 'number',
        width: 160,
        editable: false,
        
        valueGetter: (params:any):number => {
            // Calculate the difference between contract_price and proposal_price
            const delta = params.row?.total_contract_price - params.row?.total_proposal_price;
            // Get the absolute value of the difference
            return Math.abs(delta);
        },
        renderCell: (params:any) => {
            // Set the text color to red if delta is non-zero, otherwise black
            const color = params.value !== 0 ? "red" : "black";
    
            return (
                <Typography variant="body2" style={{ fontWeight: 600, color }}>
                    {`$${params.value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`}
                </Typography>
            );
        },
    }
    
];

const comparisonItemColumns:GridColDef[] = [
    {
        field: 'plan',
        headerName: 'Plan',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'elevation',
        headerName: 'Elevation',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'option',
        headerName: 'Option',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'option_code',
        headerName: 'Option Code',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'quantity',
        headerName: 'Site Quantity',
        width: 160,
        type: 'number',
        sortable: true,
        editable: false
    },
    {
        field: 'proposal_quantity',
        headerName: 'Proposal Quantity',
        width: 160,
        type: 'number',
        sortable: true,
        editable: false
    },
    {
        field: 'proposal_price',
        headerName: 'Proposal Price',
        width: 160,
        type: 'number',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
              ? `$${value.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}`
              : '';
          },
          valueSetter: (params: any) => {
            const value = params.value;
            // Remove dollar sign and commas, then convert to a number
            const numberValue = value
              ? parseFloat(value.replace(/[^0-9.-]+/g, ''))
              : null;
            return { ...params.row, total_contract_price: numberValue };
          }
    },
    {
        field: 'contract_quantity',
        headerName: 'Contract Quantity',
        width: 160,
        type: 'number',
        sortable: true,
        editable: false
    },
    {
        field: 'contract_price',
        headerName: 'Contract Price',
        width: 160,
        type: 'number',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
              ? `$${value.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}`
              : '';
        },
     
    }, 
    {
        field: 'delta',
        headerName: 'Delta',
        type: 'number',
        width: 160,
        editable: false,
        
        valueGetter: (params:any):number => {
            // Calculate the difference between contract_price and proposal_price
            const delta = params.row?.contract_price - params.row?.proposal_price;
            // Get the absolute value of the difference
            return Math.abs(delta);
        },
        renderCell: (params:any) => {
            // Set the text color to red if delta is non-zero, otherwise black
            const color = params.value !== 0 ? "red" : "black";
    
            return (
                <Typography variant="body2" style={{ fontWeight: 600, color }}>
                    {`$${params?.value?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`}
                </Typography>
            );
        },
    }
];

const useSovItemColumns = (props:any):GridColDef[] => {
    const {proposal_numbers, contract_numbers} = useAppSelector((state:any) => state.dropdown);
   
    return [
        {
            field: 'lot_number',
            headerName: 'Lot #',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 220,
            type: 'string',
            headerAlign: 'left',
            sortable: true,
            editable: false
        },  
        {
            field: 'plan',
            headerName: 'Plan',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'elevation',
            headerName: 'Elevation',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option',
            headerName: 'Option',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option_code',
            headerName: 'Option Code',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false
        },
        {
            field: 'proposal_id',
            headerName: 'Proposal #',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false,
            valueGetter: (params:any) => {
                const proposal = proposal_numbers.find((p:any) => {
                    return p?._id === params?.row?.proposal_id
                });
                return proposal?.proposal_rev_no;

            }
            
        },
        {
            field: 'proposal_quantity',
            headerName: 'Proposal Quantity',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false
        },
        {
            field: 'proposal_price',
            headerName: 'Proposal Price',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false,
            valueGetter: (params: any): string => {
                const value = params.value;
                return value !== undefined && value !== null
                    ? `$${value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`
                    : '';
            },
        },
        {
            field: 'contract_id',
            headerName: 'Contract #',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false,
            valueGetter: (params:any) => {
                const contract = contract_numbers.find((c:any) => {
                    return c?._id === params?.row?.contract_id
                });
                return contract?.contract_number;

            }
        },
        {
            field: 'contract_quantity',
            headerName: 'Contract Quantity',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false
        },
        {
            field: 'contract_price',
            headerName: 'Contract Price',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false,
            valueGetter: (params: any): string => {
                const value = params.value;
                return value !== undefined && value !== null
                    ? `$${value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`
                    : '';
            },
        }
    ];
}

const ContractPricingColumns:GridColDef[] = [
    {
        field: 'lot_number',
        headerName: 'Lot #',
        width: 120,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    },
    {
        field: 'total_contract_price',
        headerName: 'Total Contract Price',
        width: 160,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
                ? `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`
                : '';
        },
    },
    {
        field: 'gross_margin_contract',
        headerName: 'GM ($)',
        width: 160,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
                ? `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`
                : '';
        },
    },
    {
        field: 'gross_margin_percent_contract',
        headerName: 'GM (%)',
        width: 160,
        type: 'number',
        headerAlign: 'right',
        sortable: true,
        editable: false,
        valueGetter: (params: any): string => {
            const percentageValue = params.value / 100;  
            return `${percentageValue.toLocaleString('en-US', {
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
        },
    },
];

const useContractItemPricingColumns = ():GridColDef[] => {
    const {project_id} = useParams();
    const {selectedItem} = useAppSelector((state:any) => state.api);
    const params = useMemo(() => ({
        project_id: project_id || "", 
        job_type_id: selectedItem?.job_type_id?._id || ""
    }),[project_id, selectedItem])
    const {data: contract_numbers} = useGetContractNumbersQuery(params, {
        skip: !params.project_id || !params.job_type_id
    });

    // Column definitions
    const columns = useMemo(() => ([
        {
            field: 'plan',
            headerName: 'Plan',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'elevation',
            headerName: 'Elevation',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option',
            headerName: 'Option',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'option_code',
            headerName: 'Option Code',
            width: 160,
            type: 'string',
            sortable: true,
            editable: false
        },
        {
            field: 'contract_id',
            headerName: 'Contract #',
            width: 160,
            type: 'string',
            sortable: true,
            editable: true,
            valueGetter: (params:any) => {
                const contract = contract_numbers?.data?.find((c:any) => {
                    return c?._id === params?.row?.contract_id
                });
                return contract?.contract_number;

            },
            renderEditCell: (params:any) => (
                <Autocomplete
                    value={contract_numbers?.data?.find((contract:any) => contract?._id === params?.value)?.contract_number}
                    isOptionEqualToValue={(option, value) => {
                        const contract = contract_numbers?.data?.find((p:any) => p.proposal_rev_no === option);
                        return contract?._id === value;
                    }}                 
                    options={contract_numbers?.data?.map((contract:any) => contract?.contract_number) || []}
                    getOptionLabel={(option:any) => option}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                        const contract = contract_numbers?.data?.find((contract:any) => contract?.contract_number === newValue);
                        params.api.setEditCellValue({ id: params.id, field: 'contract_id', value: contract?._id }, event);
                    }}
                    fullWidth 
                />),
        },
        {
            field: 'contract_price',
            headerName: 'Contract Price',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false,
            valueGetter: (params: any): string => {
            const value = params.value;
            return value !== undefined && value !== null
                ? `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`
                : '';
        },
        },
        {
            field: 'contract_quantity',
            headerName: 'Contract Quantity',
            width: 160,
            type: 'number',
            sortable: true,
            editable: false
        },
    ]), [contract_numbers]);

    return columns;
};

export default {
    SubjobDetailColumns, 
    useSubjobItemColumns, 
    ReleaseInfoColumns,
    ReleaseInfoItemColumns, 
    CostsColumns,
    ProposalPricingColumns,
    useProposalItemColumns,
    ContractPricingColumns,
    useContractItemPricingColumns,
    useCostItemColumns,
    ProposalContractComparisonColumns,
    comparisonItemColumns,
    useSovItemColumns
}