import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { hideSnackbar } from "./snackbarSlice";

interface CustomSnackProps {
  status: string;
  message: string;
}

const CustomSnack: React.FC<CustomSnackProps> = () => {
  const dispatch = useAppDispatch();
  const snack = useAppSelector((state:any) => state.snack)

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideSnackbar());
  };

  const handleExited = () => {
    dispatch(hideSnackbar());
  }

  const errorAnchorOrigin:any = { horizontal: 'right', vertical: 'top' };
  const successAncorOrigin:any = { horizontal: 'left', vertical: 'bottom' };

  return (
    <Snackbar
      anchorOrigin={(snack?.type === "success") ? successAncorOrigin : errorAnchorOrigin}
      open={snack?.isOpen}
      autoHideDuration={6000}
      TransitionProps={{
        onExited: handleExited
      }}
      onClose={handleCloseSnackbar}
      sx={{width: "auto"}}
    >
      <Alert onClose={handleCloseSnackbar} severity={snack?.type} sx={{ width: '100%' }}>
        {`${snack?.message} ${snack?.type === "error" ? "\u{1F614}" : ""}`}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnack;
