import React, { useMemo, useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import CloseIcon from '@mui/icons-material/Close';
import DeviceHubSharpIcon from '@mui/icons-material/DeviceHubSharp';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import { useAppDispatch } from '../../../app/hooks';
import { getProjectTemplate } from '../../reports/reportSlice';

const useSpeedDialLinks = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {project_id} = useParams();
    
    const handleExportClick = () => {
        dispatch(getProjectTemplate({
            service: "app",
            url: `/projects-template`
        }));
    } 
    const links = useMemo(() => ([
        { 
            label: "Home", 
            icon: DashboardSharpIcon,
            condition: true,
            action: () => navigate("/")
        },
        { 
            url: `${location.pathname}/sov`, 
            label: "Schedule of Values", 
            icon: DeviceHubSharpIcon,
            condition: location.pathname.split("/")[1] === "projects" && project_id ,
            action: () => navigate(`/projects/${project_id}/sov`)
        },
        { 
            label: "Export Project Template", 
            icon: FileDownloadSharpIcon,
            condition: location.pathname.split("/")?.some((str:string) => str ==="projects"),
            action: handleExportClick
        },
    ]),[location]);

    return links;
}


function BasicSpeedDial() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const links = useSpeedDialLinks();

    return (
        <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'fixed', bottom: "10vh", right: 16 }}
        icon={<SpeedDialIcon openIcon={<CloseIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        >
        {links
        .filter(link => link.condition) // Filter links based on condition  
        .map((link, index) => (
            <SpeedDialAction
            key={index}
            icon={<link.icon />}
            tooltipTitle={link.label}
            onClick={() => link.action()} // action function
            />
        ))
        }
        </SpeedDial>
    );
}

export default BasicSpeedDial;
