import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { currentAuthenticatedUser, logout, reset } from '../auth/authSlice';
import Spinner from '../spinner/Spinner';
import CustomSnack from '../components/snackbar/CustomSnack';
import BasicSpeedDial from '../components/speed-dial/BasicSpeedDial';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isAuthenticated, isLoading, user, suiteDetailsConfirmed } = useAppSelector((state: any) => state.auth);
    const snack = useAppSelector((state:any) => state.snack)

    useEffect(() => {
        const checkAuth = async () => {
            try {
                await dispatch(currentAuthenticatedUser()).unwrap();
            } catch (error) {
                dispatch(logout());
                dispatch(reset());
                navigate('/login');
            }
        };
        if (!user) {
            checkAuth();
        }
    }, [dispatch, navigate, user, isAuthenticated]);

    useEffect(() => {
        if(!suiteDetailsConfirmed) navigate("/");
    },[suiteDetailsConfirmed]);

    if (isLoading || (!user && !isAuthenticated)) {
        return <Spinner />;
    }

    return (
        <>
            {children}
            <BasicSpeedDial />
            <CustomSnack status={snack?.type} message={snack?.message} />
        </>
    );
};

export default PrivateRoute;
