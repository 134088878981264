import React, { useEffect, useState } from "react";
import MainContent from "./MainContent";
import SideNav from "./SideNavbar";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ResponsiveAppBar from "./ResponsiveAppBar";
import styles from './layout.module.css';
import { setIsMobile } from "./layoutSlice";

function Layout() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isAuthenticated = useAppSelector((state: any) => state.auth.isAuthenticated);
    const { isSidebarOpen, layoutStyle } = useAppSelector((state: any) => state.layout);
    const [currentStyle, setCurrentStyle] = useState<any>({});

    useEffect(() => {
        (isMobile) ? dispatch(setIsMobile(true)) : dispatch(setIsMobile(false));     
        setCurrentStyle(layoutStyle);
    }, [layoutStyle, isSidebarOpen, isMobile]);

    return (
        <>
            <Box className={styles.layout}>
                <Box sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    height: "64px",
                    width: "100%",
                    marginBottom: "0.5rem"
                }}>
                    {isAuthenticated && ( <ResponsiveAppBar /> )}
                </Box>
                
                <Box sx={{position: "relative", top: (isMobile) ? "0": "64px", margin: (isMobile) ? "4rem 0 0 0" : "0", display: "flex",flexDirection: "row"}}>  
                    <Box sx={{ height: currentStyle?.height}}>
                        {isAuthenticated && !isMobile && ( <SideNav /> )}
                    </Box>
                    <Grid container  sx={{ ...currentStyle}}>
                        <MainContent />
                    </Grid>
                </Box>
            </Box>      
        </>
    );
}

export default Layout;
