import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { store } from '../../app/store';
import { reset } from '../auth/authSlice';


export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include'
});

// Define a service using a base URL and expected endpoints
export const apiSliceV2 = createApi({
  reducerPath: 'apiV2',
  baseQuery,
  tagTypes: ['Data', '/starts', '/projects', 'organizational-units/company'],  
  endpoints: (builder) => ({
    getData: builder.query({
      query: ({ url, params}) => ({
        url,
        params
      }),
      transformErrorResponse: async (response, meta:any, arg) => {
        if (response.status === 401) {
          store.dispatch(reset())
        }
    
        // Return the modified error response or any other processing needed
        return response.status === 404 ? "Not Found" : response?.data || "An error occurred";
      },
      providesTags: (result, error, { id, tagName }) => [
        { type: tagName || 'Data', id },  // Use dynamic tagName or fallback to 'CustomTag'
      ],
    }),
    createData: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id, tagName }) => {
        if(error) return [];
        return [{ type: tagName, id }];
      }, 
    }),
    updateData: builder.mutation({
        query: ({ url, body }) => ({
          url,
          method: 'PUT',
          body,
        }),
        invalidatesTags: (result, error, { id, tagName }) => {
          if(error) return [];
          return [{ type: tagName, id }];
        },   
    }),
    deleteData: builder.mutation({
      query: ({ url, ids }) => {
        if(ids && Array.isArray(ids)){
          return{ url, body: { ids }, method: 'DELETE' } 
        }
        return{
          url,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, { id, tagName }) => {
        if(error) return [];
        return [{ type: tagName, id }];
      }, 
      transformErrorResponse: (response, meta, arg) => {
        if ('status' in response) {
          // Assuming the error response has a status and message property
          const errorMessage = (response.data as { message?: string })?.message;

          return {
            status: response.status,
            message: errorMessage,
          };
        } else {
          // Handle the case where it's a SerializedError or any other error
          return {
            status: 'unknown',
            message: response || 'An unknown error occurred',
          };
        }
      },
    }),
    uploadData: builder.mutation({
      query: ({ url, body }) => {
        const formData = new FormData();
        for(const key in body){
          if (body.hasOwnProperty(key)) {
            formData.append(key, body[key]);
          }
        }

        return {
          url,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error, { id, tagName }) => {
        if(error) return [];
        return [{ type: tagName, id }];
      }, 
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetDataQuery,
  useCreateDataMutation,
  useUpdateDataMutation,
  useDeleteDataMutation,
  useUploadDataMutation
} = apiSliceV2;
