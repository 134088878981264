import React, { useCallback, useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import CancelIcon from "@mui/icons-material/Close";
import styles from "./detail.module.css";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { apiRouteMap } from "../../routes/urls";
import { deleteDoc, updateDoc } from "../../api/apiSlice";
import { useUpdateDataMutation, useDeleteDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../snackbar/snackbarSlice";

function PanelContent(props:any) {
    const {row, handleRefresh, items, routeSegment, columns, toolbarComponent, toolbarProps} = props;
    const [rows, setRows] = useState<any>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const dispatch = useAppDispatch();
    const [updateData] = useUpdateDataMutation();
    const [deleteData] = useDeleteDataMutation();

    useEffect(() => {
      if(items) setRows(items);
      return () => {
        setRows([]);
      }
    },[row, items]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
    
    const handleDeleteItem = (id: GridRowId) => async () => {
      try {
        await deleteData({
          tagName: routeSegment,
          url: apiRouteMap.get(`${routeSegment}/items`).delete(id)
        }).unwrap();
        dispatch(showSnackbar({ message: `Item deleted: ${id}`, type: 'success' }));
        setRows((currentRows: any[]) => currentRows.filter((row:any) => row._id !== id));
      } catch (error:any) {
        dispatch(showSnackbar({ message: error?.message, type: 'error' }));
      }
    }
         
    const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row:any) => row._id === id);
      if (editedRow && editedRow!.isNew) {
          setRows(rows.filter((row:any) => row._id !== id));
      }
    };
    
  const processRowUpdate = useCallback(async (newRow: any) => {
    try {
      const response = await updateData({
        tagName: routeSegment,
        body: newRow,
        url: apiRouteMap.get(`${routeSegment}/items`).update(newRow._id)
      }).unwrap();
      dispatch(showSnackbar({ message: response?.message, type: 'success' }));

      setRows((rows:any) => rows.map((row:any) => (row._id === newRow._id) ? newRow : row));
      return newRow;
    } catch (error:any) {
      dispatch(showSnackbar({ message: error?.data?.message || error?.data?.message, type: 'error' }));
    }
  }, []);
  
    
    const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
      setRowModesModel(rowModesModel);
    }

    const editColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 160,
      cellClassName: 'actions',
      getActions: (props:any) => {
      const {id} = props;
      if(rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit){
        return [
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="primary"
          />
        ];   
      }
      return [
        <GridActionsCellItem
          icon={<DeleteForeverSharpIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleDeleteItem(id)}
          color="primary"
        />  
      ];
    },
    };

    const getToolbarProps = () => {
      let props = {};
      switch(routeSegment){
        case "/warehouse-orders":
          props = {
            toolbar: {
              order: row,
              orderType: 'warehouse-order',
              order_id: row?._id, 
              handleRefresh,
              isUpdateMode: true,
              action: true
            }
          }
          break;
        case "/starts":
          props = {
            toolbar: {
              lot: row,
              handleRefresh
            }
          }
          break;
        default:
          break;
      }
      return props;
    }

    return (
    <Grid container justifyContent="center" alignItems="center" className={styles.detailPanel} spacing={2}>
      <Grid item sm={12} xs={12} sx={{position: "sticky"}}>
        <DataGridPro
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(255,255,255,0.5)",
            padding: 2,
            height: "auto",
            maxHeight: "100%",
            '& .MuiDataGrid-columnHeader': {
              whiteSpace: 'normal',
              lineHeight: '1.5',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              overflow: 'visible',
              whiteSpace: 'normal',
              lineHeight: '1.5',
              wordWrap: 'break-word',
            },
          }}
          key={row}
          autosizeOnMount={true}
          className={styles.gridStyle}
          columns={[editColumn, ...columns] || []}
          rows={rows || []}
          editMode="row"
          getRowId={row => row._id || row?.id || row?.order_id}
          density="compact"
          rowHeight={60}
          rowBuffer={10}
          rowReordering
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            return params.isEditable ? `${styles.editableChild}` : '';
          }}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: toolbarComponent
          }}
          slotProps={getToolbarProps()}
        />
      </Grid>
    </Grid>
  );
}

export default PanelContent;


  