import { GridColDef } from "@mui/x-data-grid-premium";

const jobTypeColumns:GridColDef[] = [
    {
        field: 'company_id',
        headerName: 'Company ID',
        type: 'string',  
        width: 200,
        filterable: false,
        editable: false,
        hideable: true
    },
    {
        field: 'division_id',
        headerName: 'Division ID',
        type: 'string', 
        width: 200,
        filterable: false,
        editable: false,
        valueGetter: (params:any) => {
            return params?.value?.division_number || "";
        }

    },
    {
        field: 'job_type_number',
        headerName: 'Job Type Number',
        type: 'string',
        width: 80,
        filterable: true,
        editable: true,
    },
    {
        field: 'job_type_description',
        headerName: 'Job Type Description',
        type: 'string',
        width: 220,
        filterable: true,
        editable: true,
    },
    {
        field: 'construction_type',
        headerName: 'Construction Type',
        type: 'string',
        width: 220,
        filterable: true,
        editable: true,
    },
    {
        field: 'contract_type',
        headerName: 'Contract Type',
        type: 'string',
        width: 200,
        filterable: true,
        editable: true,
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        type: 'date',
        width: 180,
        filterable: true,
        editable: false,
        valueGetter: (params:any) => params.value ? new Date(params.value) : new Date(),
        valueFormatter: (params: any) => {
            if (!params.value) {
              return '';
            }
            const date = new Date(params.value);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
          },
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        type: 'string',
        width: 150,
        filterable: true,
        editable: false,
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        type: 'date',
        width: 180,
        filterable: true,
        editable: false,
        valueGetter: (params:any) => params.value ? new Date(params.value) : new Date(),
        valueFormatter: (params: any) => {
            if (!params.value) {
              return '';
            }
            const date = new Date(params.value);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
          },
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        type: 'string',
        width: 150,
        filterable: true,
        editable: false,
    }
];

export default jobTypeColumns;