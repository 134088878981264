import React, { useEffect, useState } from 'react';
import { TextField, Grid,  styled, useTheme, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { useParams } from 'react-router-dom';
import ObjectID from 'bson-objectid';
import { useGetSovItemsQuery } from '../../dropdowns/dropdownApi';
import { useCreateDataMutation } from '../../api/apiSliceV2';

const HighlightedText = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 500
}));

const StyledTableHead = styled('span')(({ theme }) => ({
    fontWeight: 500
}));
  
const AddItems = (props:any) => {
    const {lot} = props;
    const [query, setQuery] = useState('');
    const {selectedItem} = useAppSelector((state:any) => state.api);
    const [filteredItems, setFilteredItems] = useState<any>([]);
    const {project_id, start_id} = useParams();
    const {data: sovItems} = useGetSovItemsQuery({project_id: project_id || "", job_type_id: selectedItem?.job_type_id?._id})
 
    const [createData] = useCreateDataMutation();


    useEffect(() => {
        if (sovItems?.data && sovItems?.data?.length > 0) {
            const filtered = sovItems?.data[0]?.items?.filter((item: any) => {
                const plan = item?.plan;
                const elevation = item?.elevation;
                const option = item?.option;
                const filteredItem = `${plan}${elevation}${option}`.toLowerCase().includes(query.toLowerCase());
                return filteredItem;
            });
            setFilteredItems(filtered);
        }
      }, [query, sovItems]);

    const handleCreateStartItem = async (lotId:string, sovItem:any) => {
        try {
            const startItem = {
                _id: new ObjectID(),
                lot_id: lotId,
                schedule_of_values_id: sovItem?.item_id,
                plan: sovItem?.plan,
                elevation: sovItem?.elevation,
                option: sovItem?.option,
                option_code: sovItem?.option_code || "",
                quantity: 1,
            }

            await createData({
                tagName: "/starts",
                body: [startItem],
                url: `/starts/items?start_id=${start_id || selectedItem?._id}`
            });

        } catch (error) {
            console.log("Error creating start item")
        }
    };
    
    const handleSearch = (event:any, lotId:string) => {
        const searchQuery = event.target.value.toLowerCase();
        const formatted = searchQuery.replace(/\s+/g, ''); 
        setQuery(formatted);
    };

    return (
        <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", maxHeight: "400px", zIndex: 999}}>
            <Grid container justifyContent="flex-start">
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <TextField
                        label="Search Schedule of Values"
                        variant="outlined"
                        fullWidth
                        value={query}
                        size='small'
                        margin='dense'
                        hiddenLabel
                        onChange={(e:any) => handleSearch(e, lot?._id)}
                    /> 
                </Grid> 

                {query && filteredItems && (
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", maxHeight: "400px" }}>
                        <TableContainer component={Paper} sx={{display: "flex", justifyContent: "center", height: "auto", overflowY: "auto", width: "auto" }}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell><StyledTableHead>Plan</StyledTableHead></TableCell>
                                <TableCell><StyledTableHead>Elevation</StyledTableHead></TableCell>
                                <TableCell><StyledTableHead>Option</StyledTableHead></TableCell>
                                <TableCell><StyledTableHead>Option Code</StyledTableHead></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredItems && filteredItems?.map((item:any) => (
                                <TableRow key={item._id || item?.item_id || item?.id} onClick={() => handleCreateStartItem(lot?._id, item)} sx={{ cursor: 'pointer', height: "24px" }}>
                                    <TableCell><HighlightedText>{item?.plan}</HighlightedText></TableCell>
                                    <TableCell><HighlightedText>{item?.elevation}</HighlightedText></TableCell>
                                    <TableCell><HighlightedText>{item?.option}</HighlightedText></TableCell>
                                    <TableCell><HighlightedText>{item?.option_code || ""}</HighlightedText></TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default AddItems;
