import { Grid } from "@mui/material";
import DetailHeader from "../../components/header/DetailHeader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useState, useEffect, useMemo } from "react";
import { AutocompleteOptions } from "../../../utils/interfaces";
import { useGetAllJobTypesQuery, useGetBudgetRevisionsQuery } from "../../dropdowns/dropdownApi";
import { apiRouteMap } from "../../routes/urls";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { useUpdateDataMutation } from "../../api/apiSliceV2";
import { useLocation, useParams } from "react-router-dom";
import { setSelectedItem } from "../../components/stepper/stepperSlice";

export const translationRequired = [
    "proposal_reference",
    "budget_reference",

    "job_types",
    "job_type_id"
];

const ProposalHeader = (props:any) => {
    const {isHeaderVisible} = props;

    const { company, division } = useAppSelector((state: any) => state.auth);
    const { statuses } = useAppSelector((state: any) => state.dropdown);
    const dispatch = useAppDispatch();
    const { selectedItem } = useAppSelector((state: any) => state.api);
    const {project_id} = useParams();

    const location = useLocation();
    const path = (location.pathname.split("/").length > 3) ?
    `/${location.pathname.split("/")[3]}`
    :
    `/${location.pathname.split("/")[1]}`;
    const [updateData] = useUpdateDataMutation();
    const [selectedJobType, setSelectedJobType] = useState<string>("");
    const jobTypeParams = useMemo(() => ({ division_id: division?._id}), [division]);
    const {data: job_types} = useGetAllJobTypesQuery(jobTypeParams);

    const jobTypeSelectionParams = useMemo(() => {
        const job_type = job_types?.data?.find((jt:any) => jt.job_type_description === selectedJobType );
        return {project_id: project_id || "", job_type_id: job_type?._id || ""}
    },[project_id, job_types, selectedJobType]);
    const {data: budget_revision_numbers} = useGetBudgetRevisionsQuery(jobTypeSelectionParams);
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});

    useEffect(() => {
        if(job_types && statuses){
            setAutocompleteOptions((prevOptions) => ({
                ...prevOptions,
                job_type_id: {
                    data: job_types?.data,
                    options: job_types?.data?.map((job_type:any) => job_type?.job_type_description)
                },
                status: {
                    data: statuses,
                    options: statuses
                },
            }));
        }
        
    }, [job_types, statuses]);
    
      useEffect(() => {
        if (selectedJobType && budget_revision_numbers) {
            const options = budget_revision_numbers?.data?.map((b:any) => `${b?.budget_name} - ${b?.budget_rev_no}`);
            setAutocompleteOptions((prevOptions) => ({
                ...prevOptions,
                budget_reference: {
                    data: budget_revision_numbers?.data,
                    options
                },
            }));
        }
    }, [budget_revision_numbers, selectedJobType]);


    const translateIds = (jsonData:any) => {
        try {
            for (const [key, val] of Object.entries(jsonData) as [string, any]) {

                if (translationRequired.includes(key)) {
                    switch (key) {
                        case "budget_reference":
                            const budget = autocompleteOptions?.[key]?.data?.find((budget:any) => {
                                return `${budget?.budget_name} - ${budget?.budget_rev_no}` === val
                            });
                            jsonData[key] = budget?._id || val; 
                            break;
                        case "job_type_id" || "job_type":
                            const job_type = autocompleteOptions?.[key]?.data?.find((j:any) => j?.job_type_description === val);
                            jsonData[key] = job_type?._id || ""; 
                            break;
                        case "job_types":
                            if (Array.isArray(val)) {
                                const translatedJobTypes = val?.map((job_type) => {
                                    const found = autocompleteOptions?.[key]?.data?.find((j: any) => j?.job_type_description === job_type);
                                    return found?._id || job_type;   
                                });
                                jsonData[key] = translatedJobTypes;   
                            }   
                            break;
                        default:
                            break;
                    }
                }
            }
            return jsonData;
        } catch (error) {
            console.log(error);
        }
        return jsonData;
    };

    const handleSave = async (formData:any) => {
        try {
            const body = translateIds(formData);
            const response = await updateData({
                tagName: "/proposals",
                url: apiRouteMap.get(path).update(selectedItem?._id),
                body,
            }).unwrap();
            dispatch(setSelectedItem(response?.data));
            dispatch(showSnackbar({ message: response?.message || 'Operation successful', type: 'success' }));
        } catch (error: any) {
            const errorMessage = error?.data?.message || 'Failed to update data';
            dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
        }
    };


    return(
        <Grid item xs={12} sx={{ justifyContent: "center" }}>
          <DetailHeader
            isHeaderVisible={isHeaderVisible}
            onJobTypeChange={setSelectedJobType}
            autocompleteOptions={autocompleteOptions}
            editable={true}
            handleSave={handleSave}
            path={path}
          />
        </Grid>
    );
}

export default ProposalHeader;