import { Box, Button, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { GridToolbarContainer} from '@mui/x-data-grid';
import React from 'react';
import { GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import { useAppSelector } from '../../../../app/hooks';

const RevisionToolbar = ({setNumRowsToAdd, numRowsToAdd, addMultipleItems, selectedColumn, handleColumnChange, step, newValue, handleValueChange, updateSelectedRows, clearRow}: any) => {
    
    const {isMobile} = useAppSelector((state:any) => state.layout);

    const exceljsPreProcess = ({ workbook, worksheet }:any) => {
        workbook.created = new Date(); // Add metadata
        worksheet.name = 'TEST'; // Modify worksheet name
        console.log(workbook)
    }

    return(
        <Box>
            <GridToolbarContainer>
                <Grid container gap={0}>
                    <Grid item sx={{display: "flex", alignItems: "baseline",  padding: 1}}>
                        <TextField
                            sx={{width: 180 }}
                            label="Add Rows"
                            type="number"
                            size="small"
                            value={numRowsToAdd}
                            onChange={(e) => setNumRowsToAdd(parseInt(e.target.value))}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        sx={{ marginLeft: 1 }}
                                        variant="text"
                                        onClick={addMultipleItems}
                                    >
                                        Add Rows
                                    </Button>
                                </InputAdornment>
                            ),
                            }}
                        /> 
                    </Grid> 
                    <Grid item sx={{display: "flex",flexDirection: isMobile ? "column" : "row", alignItems: "baseline", padding: 0}}>

                        <TextField
                            label="Select Column"
                            value={selectedColumn}
                            onChange={handleColumnChange}
                            select
                            margin="dense"
                            size="small"
                            sx={{width: 220}}
                        >
                            <MenuItem value="" disabled>
                                Select Column
                            </MenuItem>
                            {step?.column?.map((col:any) => (
                                col?.editable && (
                                    <MenuItem sx={{ fontSize: "1rem" }} key={col.field} value={col.field}>
                                        {col.headerName}
                                    </MenuItem>
                                )
                            ))}
                        </TextField>
                        <TextField
                            label="Edit Row"
                            value={newValue}
                            onChange={handleValueChange}
                            size="small"
                            
                            sx={{width: "auto"}}
                            InputProps={{
                            startAdornment: (
                                selectedColumn === "proposal_price" && (
                                <InputAdornment position="start">
                                    <Typography fontWeight={500} sx={{ margin: "0rem 1rem 0rem 0rem" }} variant="body1">
                                    f(x) =
                                    </Typography>
                                </InputAdornment>
                                )
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button  sx={{ marginLeft: 0.5 }} variant="text" onClick={updateSelectedRows}>
                                        Update
                                    </Button>
                                    <Button  sx={{ marginLeft: 0.5 }} color="error" variant="text" onClick={clearRow}>
                                        Delete
                                    </Button>
                                </InputAdornment>
                            ),
                            }}
                        />
                        
                    </Grid> 
                    <GridToolbarFilterButton sx={{fontWeight: 600}} />
                </Grid>
                
 
                </GridToolbarContainer>
        </Box>
        
    )
}

export default RevisionToolbar;
