import { useMemo } from "react";
import SubjobDetailCols from "../components/columns/SubjobDetailCols";
import WarehouseOrderCols from "../components/columns/WarehouseOrderCols";
import ActionToolbar from "../components/data-grid/toolbars/ActionToolbar";
import CustomGridToolbar from "../components/data-grid/toolbars/CustomGridToolbar";
import Steps from "../components/stepper/Steps";
import WarehouseSearchToolbar from "../warehouse/warehouse-toolbar/WarehouseSearchToolbar";

interface TabConfig {
  route: string;
  index: number;
  label: string;
  expandable: boolean;
  deletable: boolean;
  steps: any[]; // Define a better type if available
  header: boolean;
  columns: any[]; // Define a better type if available
  panelColumns: any[]; // Define a better type if available
  toolbar: React.ComponentType<any>;
  panelToolbar: React.ComponentType<any>;
  getUrl: string;
  tabWithFS?: boolean;
}

export const useStartConfig = (): { tabs: TabConfig[] } => {
  const sovColumns = SubjobDetailCols?.useSovItemColumns({});
  const costItemColumns = SubjobDetailCols?.useCostItemColumns({});
  const proposalItemColumns = SubjobDetailCols?.useProposalItemColumns();
  const contractItemPricingColumns = SubjobDetailCols?.useContractItemPricingColumns();
  const startItemColumns = SubjobDetailCols?.useSubjobItemColumns();

  const tabs = useMemo<TabConfig[]>(() => [
    {
      route: "/starts",
      index: 0,
      label: 'Release Info',
      expandable: true,
      deletable: false,
      steps: Steps?.createLotAndStartItem || [],
      header: true,
      columns: SubjobDetailCols?.ReleaseInfoColumns,
      panelColumns: startItemColumns,
      toolbar: CustomGridToolbar,
      panelToolbar: ActionToolbar,
      getUrl: '/starts/items/lots',
      tabWithFS: false,
    },
    {
      route: "/starts",
      index: 1,
      label: 'Cost Budget',
      expandable: true,
      deletable: false,
      steps: [],
      header: true,
      columns: SubjobDetailCols?.CostsColumns,
      panelColumns: costItemColumns,
      toolbar: CustomGridToolbar,
      panelToolbar: ActionToolbar,
      getUrl: '/starts/items/lots',
      tabWithFS: false,
    },
    {
      route: "/starts",
      index: 2,
      label: 'Proposal Pricing',
      expandable: true,
      deletable: false,
      steps: [],
      header: true,
      columns: SubjobDetailCols?.ProposalPricingColumns,
      panelColumns: proposalItemColumns,
      toolbar: CustomGridToolbar,
      panelToolbar: ActionToolbar,
      getUrl: '/starts/items/lots',
      tabWithFS: false,
    },
    {
      route: "/starts",
      index: 3,
      label: 'Contract Pricing',
      expandable: true,
      deletable: false,
      steps: [],
      header: true,
      columns: SubjobDetailCols?.ContractPricingColumns,
      panelColumns: contractItemPricingColumns,
      toolbar: CustomGridToolbar,
      panelToolbar: ActionToolbar,
      getUrl: '/starts/items/lots',
      tabWithFS: false,
    },
    {
      route: "/starts",
      index: 4,
      label: 'Proposal Contract Comparison',
      expandable: true,
      deletable: false,
      steps: [],
      header: true,
      columns: SubjobDetailCols?.ProposalContractComparisonColumns,
      panelColumns: SubjobDetailCols?.comparisonItemColumns,
      toolbar: CustomGridToolbar,
      panelToolbar: ActionToolbar,
      getUrl: '/starts/items/lots',
      tabWithFS: false,
    },
    {
      route: "/starts",
      index: 5,
      label: 'Schedule of Values',
      expandable: false,
      deletable: false,
      steps: [],
      header: true,
      columns: sovColumns,
      panelColumns: [],
      toolbar: CustomGridToolbar,
      panelToolbar: ActionToolbar,
      getUrl: '/starts/items',
    },
    {
      route: "/warehouse-orders",
      index: 6,
      label: 'Requested Orders',
      expandable: true,
      deletable: true,
      steps: Steps?.createWarehouseOrder || [],
      header: true,
      columns: WarehouseOrderCols?.WarehouseOrderDetailColumns,
      panelColumns: WarehouseOrderCols?.WarehouseOrderRequestItemColumns,
      toolbar: CustomGridToolbar,
      panelToolbar: WarehouseSearchToolbar,
      getUrl: '/warehouse/orders/starts',
    },
    {
      route: "/starts",
      index: 7,
      label: 'Documents',
      expandable: false,
      deletable: true,
      steps: [],
      header: true,
      columns: [],
      panelColumns: [],
      toolbar: CustomGridToolbar,
      panelToolbar: ActionToolbar,
      tabWithFS: true,
      getUrl: '/documents', // Added missing getUrl for Documents
    },
  ], [
    sovColumns, 
    costItemColumns, 
    proposalItemColumns, 
    contractItemPricingColumns,
  ]);

  return { tabs };
};

export default useStartConfig;