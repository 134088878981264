import React, { useCallback, useEffect, useMemo } from "react";
import { Grid, useTheme } from "@mui/material";
import { apiRouteMap } from "../../routes/urls";
import { useAppDispatch } from "../../../app/hooks";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { DataGridPremium, GridRowId } from "@mui/x-data-grid-premium";
import ExpandedContent from "./ExpandedContent";
import styles from '../project-detail/detail.module.css';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import { setSelectedItem } from "../../api/apiSlice";
import CustomGridToolbar from "../../components/data-grid/toolbars/CustomGridToolbar";
import { useGetDataQuery } from "../../api/apiSliceV2";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";

const ItemView = ({context, path}:any) => {
  const { selectedItem } = useAppSelector((state:any) => state.api);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {project_id, budget_id, contract_id, proposal_id} = useParams();
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);

  const params = useMemo(() => {
    const id = budget_id || proposal_id || contract_id || project_id;
    console.log(id, path)
    return {
      tagName: path,
      url: apiRouteMap.get(path).getItems(id),
    }
},[path, apiRouteMap, budget_id, proposal_id, contract_id, project_id ]);

  const {data: response, isLoading, isSuccess, isError, error} = useGetDataQuery(params);

  // Effect to handle snack messages for query and mutations
  useEffect(() => {
    if (isError && error && 'data' in error) {
      const errorMessage = `Error: ${JSON.stringify(error.data) || 'Failed to request order'}`;
      dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
    } else if (isSuccess && response) {
      dispatch(showSnackbar({ message: response?.message || "Items loaded", type: 'success' }));
    }
  }, [isError, error, isSuccess, response]);

  useEffect(() => {
    if(response?.data){
      const ref = path.split("/")[1].slice(0,-1);
      dispatch(setSelectedItem(response?.data?.[ref]));
    }
  },[response, path]);
 
  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds);
  }, []);

  const getDetailPanelContent = useCallback(({ row }:any) => {
    return( 
      <ExpandedContent
        row={row} 
        items={row?.items}
        job_type={row?.job_type} 
      />
    )
  },[selectedItem]);

  const getDetailPanelHeight = useCallback<any>(() => 'auto' as const, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" className={styles.detailGrid}>
        <Grid item sm={12} xs={12} sx={{height: "100%"}}>
          <DataGridPremium
            sx={{
              fontSize: 14, 
              fontWeight: 500, 
              border: "1px solid rgba(255,255,255,0.5)",
              '& .MuiDataGrid-row:hover': {
                backgroundColor: theme.palette.primary.main, 
                opacity: "0.8"
              },
            }}
            editMode="row"
            getRowId={(row) => row._id}
            density="compact"
            rows={response?.data?.items || response?.data || []}
            columns={context?.columns || []}
            loading={isLoading}
            rowHeight={60}
            rowBuffer={10}
            slots={{ 
              toolbar: CustomGridToolbar,
              detailPanelExpandIcon: KeyboardArrowDownSharpIcon,
              detailPanelCollapseIcon: KeyboardArrowUpSharpIcon, 
            }}
            slotProps={{toolbar: {type: "stepper", context}}}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            getRowClassName={(params:any) => 
              detailPanelExpandedRowIds.includes(params.id) ? `${styles.highlighted}` : ''
            }
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          />
          </Grid>
      </Grid>
    </>
);

}

export default ItemView;

