import { apiRouteMap } from "../../routes/urls";
import ProjectColumns from "../columns/ProjectColumns";
import { BudgetColumns, BudgetItemColumns } from '../columns/fndBudgetColumns';
import ProposalCols from "../columns/ProposalCols";
import ContractCols from "../columns/ContractCols";
import LotCols from "../columns/lotColumns";
import specificationColumns from "../columns/specificationColumns";
import RequestStart from "../../starts/start-steps/RequestStart";
import PlpColumns from "../columns/plpColumns";
import projectInformationColumns from "../columns/ProjectInformation";
import PastableGridForm from "./PastableGridForm";
import flatworkSfColumns from "../columns/flatworkSfColumns";
import concretePricingColumns from "../columns/concretePricingColumns";
import aggregatePricingColumns from "../columns/aggregatePricingColumns";
import CreateOrAddLots from "../../starts/start-steps/CreateOrAddLots";
import checklistColumns from "../columns/projectChecklistColumns";
import { ProjectPlanDesignColumns } from "../columns/projectPlanDesignColumns";
import FormSelect from "./FormSelect";
import lotColumns from "../columns/lotColumns";
import ManageWarehouseOrder from "../../warehouse/CreateWarehouseOrder";
import CreateWarehouseOrder from "../../warehouse/CreateWarehouseOrder";
import ImportComponent from "./Import";
import ProjectForm from "../forms/ProjectForm";
import FormComponent from "../forms/FormComponent";
import BudgetForm from "../forms/BudgetForm";
import ProposalForm from "../forms/ProposalForm";
import ContractForm from "../forms/ContractForm";

interface StepConfig {
    route: string;
    label: string;
    Component: React.ComponentType<any>; // Generic component type, adjust as needed
    actionType: "submit" | "import";
    idType?: string; // Optional, as not all steps have an idType
    saveUrl: (id?: string) => string; // Function that returns a string URL
    importUrl?: (id: string, jobType?: string) => string; // Optional function that returns a string URL, with jobType as an optional parameter
    column: any; // Adjust the type based on the shape of your column data
    isOptional: boolean;
    redirect?: (projectId: string, id?: string) => string; // Optional redirect function
    orderType?: string; // Optional orderType for specific cases like "pre-built order"
}

//Project Creation
const projectCreation = [
    {
        route: "/projects",
        label: "Create Project",
        Component: ProjectForm,
        actionType: "submit",
        idType: "",
        saveUrl: () => apiRouteMap.get("/projects").create,
        column: ProjectColumns,
        isOptional: false,
        redirect: (project_id:string) => `/projects/${project_id}`
    },
];

const projectInformationCreation = [
    {
        route: "/project-notes",
        label: "Add Notes",
        Component: FormSelect,
        actionType: "submit",
        idType: "project_id",
        saveUrl: () => apiRouteMap.get("/project-notes").create,
        importUrl: (id:string) => `/projects/information/import?project_id=${id}`,
        column: projectInformationColumns,
        isOptional: false
    },
];

const projectPlanDesignCreation = [
    {
        route: "/plan-designs",
        label: "Add Plan Design",
        Component: FormSelect,
        actionType: "submit",
        idType: "project_id",
        saveUrl: () => apiRouteMap.get("/plan-designs").create,
        column: ProjectPlanDesignColumns,
        isOptional: false
    },
];

//Specification Creation
const specCreation = [
    {
        route: "/specifications",
        label: "Add Specs",
        Component: FormSelect,
        actionType: "submit",
        idType: "project_id",
        saveUrl: (id:string) => apiRouteMap.get("/specifications").create,
        importUrl: (id:string) => `/specifications/import?project_id=${id}`,
        column: specificationColumns?.stepTwo,
        isOptional: false
    }
];

//Checklist Creation
const checklistCreation = [
    {
        route: "/project-checklist",
        label: "Add to Checklist",
        Component: PastableGridForm,
        actionType: "submit",
        idType: "_id",
        saveUrl: (id:string) => apiRouteMap.get("/project-checklist").create,
        column: checklistColumns,
        isOptional: false
    }
];

//Budget Creation
const budgetCreation = [
    {
        route: "/budgets",
        label: "Create Budget",
        Component: BudgetForm,
        actionType: "submit",
        idType: "project_id",
        saveUrl: (id:string) => `/fnd-budgets?project_id=${id}`,
        column: BudgetColumns,
        isOptional: false
    },
    {
        route: "/budgets",
        label: "Import Budgets",
        Component: ImportComponent,
        actionType: "import",
        idType: "budget_id",
        saveUrl: (id:string) => `/fnd-budgets/import?budget_id=${id}`,
        importUrl: (id:string) => `/fnd-budgets/import?budget_id=${id}`,
        column: BudgetItemColumns,
        isOptional: false,
        redirect: (project_id:string, id:string) => `/projects/${project_id}/budgets/${id}`
    },
];

//Budget Item Creation
const budgetItemCreation = [
    {
        route: "/budgets",
        label: "Import Budgets",
        Component: ImportComponent,
        actionType: "import",
        saveUrl: (id:string) => `/fnd-budgets/import?budget_id=${id}`,
        importUrl: (id:string) => `/fnd-budgets/import?budget_id=${id}`,
        column: BudgetItemColumns,
        isOptional: false
    },
];

//Proposal Creation
const proposalCreation = [
    {
        route: "/proposals",
        label: "Create Proposal",
        Component: ProposalForm,
        actionType: "submit",
        idType: "project_id",
        saveUrl: (id:string) => `/proposals?project_id=${id}`,
        column: ProposalCols?.ProposalColumns,
        isOptional: false
    },
   {
        route: "/proposals",
        label: "Create Items",
        Component: FormSelect,
        actionType: "submit",
        idType: "proposal_id",
        saveUrl: (id:string) => `/proposals/items?proposal_id=${id}`,
        importUrl: (id:string) => `/proposals/items/import?proposal_id=${id}`,
        column: ProposalCols?.ProposalImportColumns,
        isOptional: true,
        redirect: (project_id:string, id:string) => `/projects/${project_id}/proposals/${id}`
    },
];


//Proposal Item Creation
const proposalItemCreation = [
    {
        route: "/proposals",
        label: "Create Items",
        Component: FormSelect, 
        actionType: "submit",
        idType: "proposal_id", 
        saveUrl: (id:string) => `/proposals/items?proposal_id=${id}`,
        importUrl: (id:string) => `/proposals/items/import?proposal_id=${id}`,
        column: ProposalCols?.ProposalImportColumns,
        isOptional: false

    },
];

//Contract Creation
const contractCreation = [
    {
        route: "/contracts",
        label: "Create Contract",
        Component: ContractForm, 
        actionType: "submit",
        idType: "project_id",
        saveUrl: (id:string) => `/contracts?project_id=${id}`,
        column: ContractCols?.ContractColumns,
        isOptional: false
    },
    {
        route: "/contracts",
        label: "Create Items",
        Component: FormSelect, 
        actionType: "submit",
        idType: "contract_id", 
        saveUrl: (id:string) => `/contracts/items?contract_id=${id}`,
        importUrl: (id:string) => `/contracts/items/import?contract_id=${id}`,
        column: ContractCols?.ContractImportColumns,
        isOptional: false,
        redirect: (project_id:string, id:string) => `/projects/${project_id}/contracts/${id}`
    },
];

//Contract Item Creation
const contractItemCreation = [
    {
        route: "/contracts",
        label: "Create Contract Items",
        Component: FormSelect, 
        actionType: "submit",
        idType: "contract_id", 
        saveUrl: (id:string) => `/contracts/items?contract_id=${id}`,
        importUrl: (id:string) => `/contracts/items/import?contract_id=${id}`,
        column: ContractCols?.ContractImportColumns,
        isOptional: false
    },
    
];

//Lot Creation
const lotCreation = [
    {
        route: "/lots",
        label: "Create Lots",
        Component: PastableGridForm, 
        actionType: "submit",
        idType: "project_id", 
        saveUrl: (id:string) => `/lots?project_id=${id}`,
        column: LotCols?.LotColumns,
        isOptional: false
    },
];

//Request Start
const requestStart = [
    {
        route: "/starts",
        label: "Request Start",
        Component: RequestStart, 
        actionType: "submit",
        idType: "project_id", 
        saveUrl: (id:string) => `/lots?project_id=${id}`,
        column: LotCols?.LotColumns,
        isOptional: false,
        redirect: (project_id:string, id:string) => `/projects/${project_id}/starts/${id}`
    }
];

//Start Item Creation
const createLotAndStartItem = [
    {
        route: "/starts",
        label: "Create or Add Lots",
        Component: CreateOrAddLots, 
        actionType: "submit",
        idType: "project_id",
        saveUrl: (id:string) => `/lots?project_id=${id}`,
        column: LotCols?.LotColumns,
        isOptional: true
    }
];

//PLP creation
const createPlps = [
    {
        route: "/prebuilt-orders",
        label: "Create Pre-Built Order",
        Component: FormSelect, 
        actionType: "submit",
        orderType: "pre-built order",
        idType: "project_id",
        saveUrl: (id:string) => `/prebuilt-orders?project_id=${id}`,
        importUrl: (id:string, job_type:any) => `/prebuilt-orders/items/import?project_id=${id}&job_type=${job_type?._id}`,
        column: PlpColumns,
        isOptional: false
    },
];

//Create Warehouse order
const createWarehouseOrder = [
    {
        route: "/warehouse-orders",
        label: "Create Order",
        Component: CreateWarehouseOrder, 
        actionType: "submit",
        orderType: "warehouse order",
        idType: "start_id",
        saveUrl: (id:string) => `/warehouse/orders?start_id=${id}`,
        //importUrl: (id:string, job_type:any) => `/prebuilt-orders/items/import?project_id=${id}&job_type=${job_type?._id}`,
        column: lotColumns?.LotColumns,
        isOptional: false
    },
];

//Fulfill warehouse order 
const fulfillWarehouseOrder = [
    {
        route: "/warehouse-orders",
        label: "Fulfill Order",
        Component: ManageWarehouseOrder, 
        actionType: "submit",
        idType: "project_id",
        saveUrl: (id:string) => `/warehouse/orders?id=${id}`,
        //importUrl: (id:string, job_type:any) => `/prebuilt-orders/items/import?project_id=${id}&job_type=${job_type?._id}`,
        column: lotColumns?.LotColumns,
        isOptional: false
    },
];

//Flatwork SF creation
const createFlatworkSF = [
    {
        route: "/flatwork-sfs",
        label: "Create Flatwork SF",
        Component: FormSelect, 
        actionType: "submit",
        idType: "project_id",
        saveUrl: (id:string) => apiRouteMap.get("/flatwork-sfs").createItem,
        importUrl: (id:string, job_type:any) => `/flatwork-sfs/import?project_id=${id}&job_type_id=${job_type?._id}`,
        column: flatworkSfColumns,
        isOptional: false
    },
];


//Import Concrete Pricing
const importConcretePricing = [
    {
        route: "/fnd-concrete-pricings",
        label: "Import Concrete Mix Design & Pricing",
        Component: PastableGridForm, 
        actionType: "submit",
        idType: "",
        saveUrl: (id:string) => apiRouteMap.get("/concrete/mix-design-pricings").createItem,
        column: concretePricingColumns,
        isOptional: false
    },
];

//Import Aggregate Pricing
const importAggregatePricing = [
    {
        route: "/fnd-aggregate-pricings",
        label: "Import Aggregate Pricing",
        Component: PastableGridForm, 
        actionType: "submit",
        idType: "",
        saveUrl: (id:string) => apiRouteMap.get("/concrete/aggregate-pricing").createItem,
        column: aggregatePricingColumns,
        isOptional: false
    },
];

export default {
    specCreation,
    projectCreation,
    projectInformationCreation,
    projectPlanDesignCreation,
    checklistCreation,
    budgetCreation,
    budgetItemCreation,
    proposalCreation,
    proposalItemCreation,
    contractCreation,
    contractItemCreation, 
    lotCreation,
    requestStart,
    createLotAndStartItem,
    createPlps,
    createFlatworkSF,
    importConcretePricing,
    importAggregatePricing,
    createWarehouseOrder,
    fulfillWarehouseOrder
}


