import CompanyForm from "../../components/forms/CompanyForm";
import CustomerForm from "../../components/forms/CustomerForm";
import DivisionForm from "../../components/forms/DivisionForm";
import EmployeeForm from "../../components/forms/EmployeeForm";
import FormComponent from "../../components/forms/FormComponent";
import JobTypeForm from "../../components/forms/JobTypeForm";
import companyColumns from "../columns/companyColumns";
import customerColumns from "../columns/customerColumns";
import divisionColumns from "../columns/divisionColumns";
import employeeColumns from "../columns/employeeColumns";
import jobTypeColumns from "../columns/jobTypeColumns";

// Create a Company
const createCompany = [
    {
        route: "/organizational-units/company",
        label: "Add Company",
        Component: CompanyForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/company',
        column: companyColumns,
        isOptional: false
    },
];

// Create a Company
const createDivision = [
    {
        route: "/organizational-units/divisions",
        label: "Add Division",
        Component: DivisionForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/divisions',
        column: undefined,
        isOptional: false
    },
];

// Create a Company
const createJobType = [
    {
        route: "/organizational-units/job-types",
        label: "Add Job Type",
        Component: JobTypeForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/job-types',
        column: jobTypeColumns,
        isOptional: false
    },
];

// Create a Company
const createCustomer = [
    {
        route: "/organizational-units/customers",
        label: "Add Customer",
        Component: CustomerForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/customers',
        column: customerColumns,
        isOptional: false
    },
];

// Create a Company
const createSupervisor = [
    {
        route: "/organizational-units/supervisors",
        label: "Add Supervisor",
        Component: EmployeeForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/supervisors',
        column: employeeColumns,
        isOptional: false
    },
];

// Create a Company
const createEstimator = [
    {
        route: "/organizational-units/estimators",
        label: "Add Estimator",
        Component: EmployeeForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/estimators',
        column: employeeColumns,
        isOptional: false
    },
];

// Create a Company
const createForeman = [
    {
        route: "/organizational-units/foremans",
        label: "Add Foreman",
        Component: EmployeeForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/foremen',
        column: employeeColumns,
        isOptional: false
    },
];

// Create a Company
const createJCAccountant = [
    {
        route: "/organizational-units/jc-accountants",
        label: "Add JC Accountant",
        Component: EmployeeForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/jc-accountants',
        column: employeeColumns,
        isOptional: false
    },
];

export default {
    createCompany,
    createDivision,
    createJobType,
    createCustomer,
    createSupervisor,
    createEstimator,
    createForeman,
    createJCAccountant
}