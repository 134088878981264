import { Grid } from "@mui/material";
import { GridRowId, DataGridProProps } from "@mui/x-data-grid-pro";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { getListItems } from "../../../api/apiSlice";
import CustomDataGrid from "../../../components/data-grid/CustomDataGrid";
import CustomSnack from "../../../components/snackbar/CustomSnack";
import { apiRouteMap } from "../../../routes/urls";
import ExpandedContent from "./ExpandedContent";
import comparisonColumns from "./comparisonColumns";
import styles from '../../project-detail/detail.module.css';

const ComparisonView = ({selectedContract, selectedProposal}:any) => {
  const {isAuthenticated} = useAppSelector((state:any) => state.auth);
  const {isLoading, isSuccess, isError, message} = useAppSelector((state:any) => state.api);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [rows, setRows] = useState<any>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
  const [snackMessage, setSnackMessage] = useState('');
  const path = location.pathname.split("/")[3];


  useEffect(() => {
    const fetchItems = async () => {
      try {
        //Get selected items
        const r1 = await dispatch(getListItems({
          service: "app",
          url: apiRouteMap.get(`/${path}`).getItems(selectedContract)
        }));
        //Get referenced items
        const ref_path = path === "proposals" ? "budgets" : "proposals";
        const r2 = await dispatch(getListItems({
          service: "app",
          url: apiRouteMap.get(`/${ref_path}`).getItems(selectedProposal)
        }));

        if(r1.meta.requestStatus === "fulfilled" && r2.meta.requestStatus === "fulfilled"){
          const firstItems = r1?.payload?.data?.items;
          const secondaryItems = r2?.payload?.data?.items;
          const merged = (ref_path === "budgets") ? 
            await mergeGridRows(firstItems, secondaryItems, "proposal-budget")
            :
            await mergeGridRows(firstItems, secondaryItems, "contract-proposal");
          setRows(merged);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if(isAuthenticated && selectedContract && selectedProposal) fetchItems();
  },[isAuthenticated, selectedContract, selectedProposal]);

  const mergeGridRows = async (primaryArray:any, secondaryArray:any, type:string) => {
    try{
      const merged = primaryArray?.map((primaryItem:any) => {
        const secondaryItem = secondaryArray?.find((item:any) => (item?.schedule_of_values_id || item?._id) === primaryItem?.schedule_of_values_id);
        if(type === "contract-proposal"){
          const row = calculateDeltaContractProposal(primaryItem, secondaryItem);
          return row;
        }
        const row = calculateDeltaProposalBudget(primaryItem, secondaryItem);
        return row;
      });
      return merged;
    }catch(error){
      console.log(error)
    }
  }

  const calculateDeltaContractProposal = (primaryItem:any, secondaryItem:any) => {
    const factor = 100;
    const contract_price = primaryItem?.total_price;
    const proposal_price = secondaryItem?.total_price;
    const delta = (((primaryItem?.total_price * factor)  -  (secondaryItem?.total_price * factor)) / factor).toFixed(2);
    const panelItems = {contract_items: primaryItem?.items, proposal_items: secondaryItem?.items}    
    const row = {...primaryItem, ...secondaryItem, proposal_price, contract_price, delta, ...panelItems};
    return row;
  }

  const calculateDeltaProposalBudget = (primaryItem:any, secondaryItem:any) => {
    const factor = 100;
    const proposal_price = primaryItem?.total_price;
    const budget_cost = secondaryItem?.total_cost;
    const gross_margin_dollar = Number((((primaryItem?.total_price * factor) - (secondaryItem?.total_cost * factor)) / factor).toFixed(2));
    const gross_margin_percent = proposal_price !== 0 ? 
      Number((((proposal_price - budget_cost) / proposal_price) * 100).toFixed(2)) 
    : 
      NaN;
    const panelItems = { proposal_items: primaryItem?.items, budget_items: secondaryItem?.items };
    return { ...primaryItem, ...secondaryItem, proposal_price, budget_cost, gross_margin_dollar, gross_margin_percent, ...panelItems };
  }

  useEffect(() => {
    setSnackMessage(message)
  },[message, isError, isSuccess]);
  
  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(newIds);
    },
    [],
  );
 
  const getDetailPanelContent = React.useCallback(({ row, type }:any) => {
    const rowData = row?.row;
    const items = {
      budget_items: rowData?.budget_items,
      proposal_items: rowData?.proposal_items,
      contract_items: rowData?.contract_items,
    }
    return( 
      <ExpandedContent
        row={rowData} 
        items={items} 
        columns={[]}
      />
    )
  },[rows]);

  const getDetailPanelHeight = React.useCallback<NonNullable<DataGridProProps['getDetailPanelHeight']>>(() => 'auto' as const, []);
  const cols = path === "contracts" ? comparisonColumns?.ContractProposalComparison : comparisonColumns?.ProposalBudgetComparison;

  return (
    <Grid container justifyContent="center" alignItems="center" className={styles.detailGrid}> 
      <Grid item xs={12} sx={{height: "100%"}}>
        <CustomDataGrid
          rows={rows}
          columns={cols || []}
          isLoading={isLoading}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          handleDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          getDetailPanelContent={(row:any) => getDetailPanelContent({row, type: 'proposals'})}
          getDetailPanelHeight={getDetailPanelHeight}
        />
      </Grid>
    </Grid>
    // <CustomSnack isError={isError} isSuccess={isSuccess} message={snackMessage} />
);

}

export default ComparisonView;

