import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../api/apiSliceV2';

export const dropdownApi = createApi({
  reducerPath: 'dropdownApi',
  baseQuery,
  endpoints: (builder) => ({
    getSovItems: builder.query<any, { project_id: string; job_type_id?: string }>({
      query: ({ project_id, job_type_id}:any) => ({
        url: '/sov',
        params: {project_id, job_type_id}}),
    }),
    getSovIDs: builder.query<any, { project_id: string; job_type_id?: string }>({
      query: ({ project_id, job_type_id}:any) => ({
        url: '/dropdowns/projects/sov-items',
        params: {project_id, job_type_id}}),
    }),
    getLotList: builder.query<any, void>({
      query: () => '/dropdowns/starts/lots',
    }),
    getJobTypes: builder.query<any, {project_id: string}>({
      query: ({ project_id}:any) => ({
        url: '/dropdowns/projects/job-types',
        params: {project_id}}), 
    }),
    getAllJobTypes: builder.query<any, {division_id: string}>({
      query: ({ division_id }:any) => ({
        url: '/dropdowns/job-types',
        params: {division_id}}),
    }),
    getCompanies: builder.query<any, void>({
      query: () => '/company',
    }),
    getCustomers: builder.query<any, {company_id:string}>({
      query: ({company_id }:any) => ({
        url: '/dropdowns/customers',
        params: {company_id },
      }),
    }),
    getSupervisors: builder.query<any, {project_id?:string, division_id:string}>({
      query: ({project_id, division_id }:any) => ({
        url: '/dropdowns/supervisors',
        params: {project_id, division_id },
      }),
    }),
    getForemen: builder.query<any, {project_id?:string, division_id:string}>({
      query: ({project_id, division_id }:any) => ({
        url: '/dropdowns/foremen',
        params: {project_id, division_id },
      }),
    }),
    getEstimators: builder.query<any, {project_id?:string, division_id:string}>({
      query: ({project_id, division_id }:any) => ({
        url: '/dropdowns/estimators',
        params: {project_id, division_id },
      }),
    }),
    getJCAccountant: builder.query<any, {project_id?:string, division_id:string}>({
      query: ({project_id, division_id }:any) => ({
        url: '/dropdowns/jc-accountants',
        params: {project_id, division_id },
      }),
    }),
    getProjects: builder.query<any, { company_id: string; division_id: string }>({
      query: ({ company_id, division_id }:any) => ({
        url: '/dropdowns/projects',
        params: { company_id, division_id },
      }),
    }),
    getCompanyNumbers: builder.query<any, void>({
      query: () => '/dropdowns/companies',
    }),
    getDivisionNumbers: builder.query<any, {company_id:string}>({
      query: ({company_id }:any) => ({
        url: '/dropdowns/divisions',
        params: {company_id },
      }),
    }),
    getContractNumbers: builder.query<any, { project_id: string; job_type_id: string }>({
      query: ({ project_id, job_type_id }:any) => ({
        url: '/dropdowns/projects/contracts',
        params: { project_id, job_type_id },
      }),
    }),
    getProposalRevisions: builder.query<any, { project_id: string; job_type_id: string }>({
      query: ({ project_id, job_type_id }:any) => ({
        url: '/dropdowns/projects/proposals',
        params: { project_id, job_type_id },
      }),
    }),
    getBudgetRevisions: builder.query<any, { project_id: string; job_type_id: string }>({
      query: ({ project_id, job_type_id }:any) => ({
        url: '/dropdowns/projects/budgets',
        params: { project_id, job_type_id },
      }),
    }),
    getStarts: builder.query<any, { project_id: string }>({
      query: ({ project_id}:any) => ({
        url: 'starts',
        params: {project_id}}),
    }),
    getOrdProcEnt: builder.query<any, { start_id: string }>({
      query: ({ start_id}:any) => ({
        url: '/reports/ordprocent',
        params: {start_id}}),
      // transformResponse: (response: string) => {
      //     const parsedData = parse<any>(response, { header: true });
      //     return parsedData.data;
      //   },
      // 
    }),
    getJobCostReport: builder.query<any, { start_id: string }>({
      query: ({ start_id}:any) => ({
        url: '/reports/cost-report',
        params: {start_id}
      }),
    }),
    getJobCostReportCSV: builder.query<any, { start_id: string }>({
      query: ({ start_id}:any) => ({
        url: '/reports/cost-report',
        params: {start_id}
      }),
      // transformResponse: (response: string) => {
      //     const parsedData = parse<any>(response, { header: true });
      //     return parsedData.data;
      //   },
      
    }),
    getContractItems: builder.query<any, { start_id: string }>({
      query: ({ start_id}:any) => ({
        url: '/reports/contract-items',
        params: {start_id}
      }),
    }),
    getWarehouseMaterials: builder.query<any, void>({
      query: () => ({
        url: '/warehouse/materials',
      }),
    }),
    getStartItemsByLot: builder.query<any, {start_id: string}>({
      query: ({start_id}:any) => ({
        url: '/starts/items/lots',
        params: {start_id}
      }),
      transformResponse: (response:any) => {
        const lotInformation = response?.data?.map((item:any) => {
          const delta = (((item?.total_proposal_price * 100)  -  (item?.total_contract_price * 100)) / 100).toFixed(2);

          const startItems = item?.startItems?.map((startItem:any) => {
            const itemDelta = (((startItem?.proposal_price * 100)  -  (startItem?.contract_price * 100)) / 100).toFixed(2);
            return {
                ...startItem,
                delta: itemDelta
            }
          });

          return {
            _id: item?._id,
            lot_number: item?.lot_number,
            address: item?.address,
            garage_orientation: item?.garage_orientation,
            customer_plan: item?.customer_plan,
            customer_elevation: item?.customer_elevation,
            total_budget_cost: item?.total_budget_cost,
            total_proposal_price: item?.total_proposal_price,
            total_contract_price: item?.total_contract_price,
            delta,
            gross_margin_proposal: item?.gross_margin_proposal,
            gross_margin_percent_proposal: item?.gross_margin_percent_proposal,
            gross_margin_contract: item?.gross_margin_contract,
            gross_margin_percent_contract: item?.gross_margin_percent_contract,
            startItems: startItems
          }
        });
        return lotInformation;
      },
    }),
    getAllStartItems: builder.query<any, {start_id: string}>({
      query: ({start_id}:any) => ({
        url: '/starts/items',
        params: {start_id}
      }),
      transformResponse: (response:any) => {
        return response?.data;
      },
    }),
    getWarehouseOrders: builder.query<any, {start_id: string}>({
      query: ({start_id}:any) => ({
        url: '/warehouse/orders/starts',
        params: {start_id}
      }),
      transformResponse: (response:any) => {
        return response?.data;
      },
    }),
  }),
  
});

export const {
  useGetProjectsQuery,
  useGetLotListQuery,
  useGetJobTypesQuery,
  useGetSupervisorsQuery,
  useGetSovItemsQuery,
  useGetCompanyNumbersQuery,
  useGetDivisionNumbersQuery,
  useGetContractNumbersQuery,
  useGetProposalRevisionsQuery,
  useGetBudgetRevisionsQuery,
  useGetStartsQuery,
  useGetJobCostReportQuery,
  useGetJobCostReportCSVQuery,
  useGetContractItemsQuery,
  useGetOrdProcEntQuery,
  useGetWarehouseMaterialsQuery,
  useGetStartItemsByLotQuery,
  useGetWarehouseOrdersQuery,
  useGetAllStartItemsQuery,
  useGetCompaniesQuery,
  useGetAllJobTypesQuery,
  useGetCustomersQuery,
  useGetEstimatorsQuery,
  useGetForemenQuery,
  useGetJCAccountantQuery,
  useGetSovIDsQuery

} = dropdownApi;
