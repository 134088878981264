import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Box, Grid, useTheme, Select, MenuItem, FormControl, InputLabel, Avatar } from "@mui/material";
import PagesGrid from "./dashboardCards";
import { setSuiteDetails } from "../auth/authSlice";
import { ReactComponent as SuiteImage } from '../../assets/sss.svg'; 
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { useGetCompanyNumbersQuery, useGetDivisionNumbersQuery } from "../dropdowns/dropdownApi";

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { company, division, suiteDetailsConfirmed } = useAppSelector((state) => state.auth);
  const { isMobile } = useAppSelector((state: any) => state.layout);
  const [companySelection, setCompanySelection] = useState<any>(company || {});
  const [divisionSelection, setDivisionSelection] = useState<any>(division || {});

  const { data: company_numbers } = useGetCompanyNumbersQuery();
  const params = useMemo(() => ({ company_id: companySelection?._id }), [companySelection]);
  const { data: division_numbers } = useGetDivisionNumbersQuery(params);
  
  useEffect(() => {
    if (
      companySelection &&
      Object.keys(companySelection).length > 0 &&
      divisionSelection &&
      Object.keys(divisionSelection).length > 0
    ) {
      dispatch(
        setSuiteDetails({
          company: companySelection,
          division: divisionSelection,
        })
      );
    }
  }, [companySelection, divisionSelection, dispatch]);

  const handleCompanyChange = (event: any) => {
    const selectedCompanyNumber = event.target.value;
    const selectedCompany = company_numbers?.data?.find(
      (c: any) => c.company_number === selectedCompanyNumber
    );
    setCompanySelection(selectedCompany);
  };

  const handleDivisionChange = (event: any) => {
    const selectedDivisionNumber = event.target.value;
    const selectedDivision = division_numbers?.data?.find(
      (d: any) => d.division_number === selectedDivisionNumber
    );
    setDivisionSelection(selectedDivision);
  };

  return (
    <Grid container sx={{ width: "100%", height: "100%" }}>
      {!suiteDetailsConfirmed ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            sm={6}
            xs={12}
            sx={{
              width: "100%",
              height: isMobile ? "50%" : "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
              }}
            >
              <SuiteImage
                style={{
                  width: "auto",
                  height: "100%",
                  minHeight: "360px",
                  minWidth: "360px",
                  maxHeight: "360px",
                  maxWidth: "360px",
                  objectFit: "contain",
                  fill: theme.palette.mode === "dark"
                    ? theme.palette.primary.light
                    : theme.palette.primary.main,
                }}
              />
            </Box>
          </Grid>

          <Grid item sm={6} xs={12} sx={{width: "100%", display: "flex", justifyContent: isMobile ? "center" :"flex-start"}}>
            <Box sx={{display: "flex", justifyContent: "center", flexDirection: "column", width: "16em",}}>
              <Box sx={{margin: "0 auto"}}> 
                <Avatar 
                  alt="Super Start Suite" 
                  sx={{width: "128px", height: "128px", backgroundColor: "white"}}
                  variant="square"
                >
                  <Logo 
                    style={{ 
                      maxWidth: '100%', 
                      maxHeight: '100%', 
                      objectFit: 'contain',
                      padding: "0.05em",
                      fill: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main  
                    }} 
                  />
                </Avatar>
              </Box>
              <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: isMobile ? "column" : "row",
                    marginTop: 2,
                  }}
                >
                <FormControl fullWidth sx={{ width: 120}}>
                  <InputLabel>C#</InputLabel>
                  <Select
                    value={companySelection?.company_number || ""}
                    label="C#"
                    size="medium"
                    onChange={handleCompanyChange}
                  >
                    {company_numbers?.data?.map((c: any) => (
                      <MenuItem key={c.company_number} value={c.company_number}>
                        {c.company_number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
                <FormControl fullWidth sx={{marginLeft: 0.5, marginTop: isMobile ? 0.5 : 0, width: 120 }}>
                  <InputLabel>D#</InputLabel>
                  <Select
                    value={divisionSelection?.division_number || ""}
                    label="D#"
                    size="medium"
                    onChange={handleDivisionChange}
                    renderValue={(value: any) => value ? value : <em>Nothing Selected</em>}
                  >
                    {division_numbers?.data?.map((d: any) => (
                      <MenuItem key={d.division_number} value={d.division_number}>
                        {d.division_number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>          

            </Box> 
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
          <PagesGrid />
        </Grid>
      )}
    </Grid>
  );
};

export default Dashboard;
