import { GridColDef } from "@mui/x-data-grid-premium";

const customerColumns:GridColDef[] = [
    {
        field: 'company_id',
        headerName: 'Company ID',
        type: 'string',  
        width: 200,
        editable: true,
        filterable: false,
        valueGetter: (params:any) => {
            return params?.value?.company_number || "";
        }
    },
    {
        field: 'customer_number',
        headerName: 'Customer Number',
        type: 'number',
        width: 80,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_description',
        headerName: 'Customer Description',
        type: 'string',
        width: 220,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_legal_name',
        headerName: 'Legal Name',
        type: 'string',
        width: 180,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_principal',
        headerName: 'Principal',
        type: 'string',
        width: 180,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_state',
        headerName: 'State',
        type: 'string',
        width: 150,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_country',
        headerName: 'Country',
        type: 'string',
        width: 150,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_status',
        headerName: 'Status',
        type: 'string',
        width: 150,
        filterable: true,
        editable: true,
        // Optional: Custom rendering or color based on status
        renderCell: (params:any) => (
            <span style={{ color: params.value === 'ACTIVE' ? 'green' : params.value === 'INACTIVE' ? 'orange' : 'red' }}>
                {params.value}
            </span>
        ),
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        type: 'date',
        valueGetter: (params:any) => params.value ? new Date(params.value) : undefined,
        valueFormatter: (params: any) => {
            if (!params.value) {
              return '';
            }
            const date = new Date(params.value);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        },
        width: 180,
        filterable: true,
        editable: false,
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        type: 'string',
        width: 150,
        filterable: true,
        editable: false,
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        type: 'date',
        valueGetter: (params:any) => params.value ? new Date(params.value) : undefined,
        valueFormatter: (params: any) => {
            if (!params.value) {
              return '';
            }
            const date = new Date(params.value);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        },
        width: 180,
        filterable: true,
        editable: false,
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        type: 'string',
        width: 150,
        filterable: true,
        editable: false,
    }
];

export default customerColumns;