import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { currentAuthenticatedUser, login, resetPassword } from './authSlice';
import { Avatar, Button, TextField, Grid, Box, Typography, Link, Snackbar, Alert, AlertColor, useTheme } from '@mui/material';
import Spinner from '../spinner/Spinner';
import {ReactComponent as Logo} from '../../assets/Logo.svg';
import { useNavigate } from 'react-router-dom';
import { Copyright } from './Login';

const PasswordReset = () => {
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {isAuthenticated, isLoading, isError, isSuccess, message } = useAppSelector((state) => state.auth);
  const {isMobile} = useAppSelector((state:any) => state.layout);
  const [formData, setFormData] = useState({
    username: '',
    oldPassword: '',
    newPassword: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');
  
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate('/');
    }
    if (isError) {
      setSnackbarOpen(true);
    }
  }, [isAuthenticated, isError, isSuccess, navigate, message]);

  const onChange = (e:any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (event:any) => {
    event.preventDefault();
    dispatch(resetPassword({
      username: formData.username,
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    }))
    .unwrap()
    .then(() => {
      setSnackbarMessage('Password reset successfully');
      setSnackbarSeverity('success');
      dispatch(login({ username: formData.username, password: formData.newPassword }));
    })
    .catch((error) => {
      setSnackbarMessage(message || 'Password reset failed');
      setSnackbarSeverity('error');
    })
    .finally(() => {      
      setSnackbarOpen(true);
    });
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent<Element, Event> | Event, 
    reason?: string
  ) => {
      if (reason === 'clickaway') {
          return;
      }
      setSnackbarOpen(false);
  };
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Grid container spacing={0}  sx={{ margin: "0 auto", height: '100vh', width: "100vw", maxHeight: '1200px'}}>
      <Grid item xs={12} sm={6} sx={{
          height: (isMobile) ? "40%" : "100%",
          width: (isMobile) ? "40%" : "100%",
          display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            p: 4,
          }}>
            <Box sx={{
              height: "auto", 
              width: "100%",  
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              marginBottom: (!isMobile) ? 18 : 2,
            }}>
              <Logo style={{
                fill: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main, 
                maxWidth: '600px', 
                maxHeight: '360px' 
              }} />
            </Box>
            
      </Grid>
      <Grid item xs={12} sm={6} sx={{
        height: (isMobile) ? "60%" : "100%", 
        display: 'flex', flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: (isMobile) ? 'center' : 'flex-start',    
        p: 4
        }}>
        <Typography component="h1" variant="h4" sx={{textAlign: "center", mb: 2, fontWeight: "400" }}>
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 400 }}>
          <TextField
            size="small"
            margin="dense"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            value={formData.username}
            onChange={onChange}
            autoFocus
          />
          <TextField
            size="small"
            margin="dense"
            required
            fullWidth
            id="oldPassword"
            label="Old Password"
            name="oldPassword"
            value={formData.oldPassword}
            onChange={onChange}
            type="password"
          />
          <TextField
            size="small"
            margin="dense"
            required
            fullWidth
            id="newPassword"
            label="New Password"
            name="newPassword"
            value={formData.newPassword}
            onChange={onChange}
            type="password"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Reset</Button>
        </Box>
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box sx={{ mt: 5 }}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );

}

export default PasswordReset;
