import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField, useTheme, Stack } from "@mui/material";
import { Autocomplete } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../data-grid/ConfirmationDialog';
import { isValid, parseISO } from 'date-fns';
import { formatDateStringWithTime, formatDateString } from "../../../utils/formatDateString";
import NavigationHeader from './NavigationHeader';
import CustomButton from '../button/CustomButton';
import { DateField } from '@mui/x-date-pickers/DateField/DateField';
import { useDeleteDataMutation, useUpdateDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../snackbar/snackbarSlice';
import { apiRouteMap } from '../../routes/urls';
import { AutocompleteOptions } from '../../../utils/interfaces';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setSelectedItem } from '../../api/apiSlice';
import Spinner from '../../spinner/Spinner';

const dateWithTime = [
    'created_at',
    'updated_at'
];

interface DetailHeaderProps {
    isHeaderVisible: boolean;
    editable: boolean;
    onJobTypeChange?: any;
    autocompleteOptions: AutocompleteOptions;
    path: string;
    handleSave: (event: MouseEvent) => void;
}   
//console.log
const DetailHeader: React.FC<DetailHeaderProps> = ({
    isHeaderVisible,
    editable,
    onJobTypeChange,
    autocompleteOptions,
    path,
    handleSave 
}) => {
    const { company, division } = useAppSelector((state: any) => state.auth);
    const { selectedItem, selectedProject } = useAppSelector((state: any) => state.api);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState<any>(selectedItem);
    const [headerFields, setHeaderFields] = useState<any>({});
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [deleteData] = useDeleteDataMutation();



    const excludedFields = [
        '_id',
        '_v',
        "__v",
        'job_id',
        'project_id',
        'budget_id',
        'proposal_id',
        'contract_id',
        'created_by',
        'created_at',
        'updated_by',
        'updated_at',
        "items",
        "budget_items",
        "order_items",
        "job_number",
        "subjob_number",
        "schedule_of_values_id",
        "job_types",
        "company_id",
        "division_id",
        "company_number",
        "division_number"
    ];

    const getKey = (field: string): string => field;
    useEffect(() => {
        if (selectedItem) {
            const primitiveFields: Record<string, any> = {};
            const objectFields: Record<string, any> = {};
    
            // Separate primitive values and objects
            Object.entries(selectedItem).forEach(([key, value]) => {
                if (!excludedFields.includes(key)) {
                    if (typeof value === 'object' && value !== null) {
                        objectFields[key] = value; // Push objects to a separate dictionary
                    } else {
                        primitiveFields[key] = value; // Keep primitives in their own dictionary
                    }
                }
            });
    
            // Combine primitives first, then objects
            const filteredItem = { ...primitiveFields, ...objectFields };
    
            setHeaderFields(filteredItem);
            setFormData(filteredItem);
        }
    }, [company, division, selectedItem]);
        

    const isValidDate = (dateString: string, key: string): boolean => {
        if (["job_number", "company_number", "division_number", "subjob_number"].includes(key)) return false;
        const date = parseISO(dateString);
        return isValid(date);
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const confirmDelete = () => {
        setOpen(!open);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const handleDateChange = (field: string, newValue: Dayjs | null) => {
        setFormData((prevState: any) => ({
            ...prevState,
            [field]: newValue,
        }));
    };

    const handleSaveClick = async () => {
        
        try {
setIsLoading(true);
            dispatch(setSelectedItem({}));
            handleSave(formData);
            const updatedItem = {
                ...selectedItem,
                ...formData,
            };
            setFormData(updatedItem);
            setHeaderFields({
                company_number: company?.company_number,
                division_number: division?.division_number,
                ...updatedItem,
            });
            toggleEditMode();
            setIsLoading(false);
        } catch (error: any) {
            const errorMessage = error?.data?.message || 'Failed to update data';
            dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
        } 
    };

    const handleDelete = async () => {
        try {
            const response = await deleteData({
                tagName: path,
                url: apiRouteMap?.get(path)?.delete(selectedItem?._id)
            }).unwrap();
            dispatch(showSnackbar({ message: response?.message || "Item deleted", type: "success" }));
            navigate(-1);
        } catch (error: any) {
            dispatch(showSnackbar({ message: error?.message || "Delete operation failed, please try again", type: "error" }));
        }
    }


    return (
        isLoading ? (
            <Spinner />
        ) : (
            <Grid container sx={{ width: "100%", height: "auto", textTransform: "uppercase", opacity: 0.9, padding: "1rem" }}>
                <Grid item xs={12} sx={{ padding: "1rem" }}>
                    <NavigationHeader selectedItem={selectedProject} />
                </Grid>
    
                {/* Upper container for primitives */}
                {isHeaderVisible && (
                    <>
                        <Grid container sx={{ width: "100%", height: "auto", padding: "1rem", display: "flex", justifyContent: "start", alignItems: "stretch" }} gap={1}>
                            {Object.entries(headerFields)
                                .filter(([key, field]) => !excludedFields.includes(key) && (typeof field !== 'object' || field === null))
                                .map(([key, field]: any, index) => (
                                    <Grid item key={index} lg={2} md={4} sm={6} xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                        {!editMode ? (
                                            <Stack direction="column" justifyContent="start" alignItems="start" spacing={1}>
                                                <Typography variant="body1" sx={{ textDecoration: "underline", fontWeight: 600 }}>
                                                    {`${key?.split("_")[0]} ${key?.split("_")[1] || ''}`}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: theme.palette.primary.main, fontWeight: 600 }}>
                                                    {isValidDate(field, key)
                                                        ? (!dateWithTime.includes(key) ? formatDateString(field) : formatDateStringWithTime(field))
                                                        : field}
                                                </Typography>
                                            </Stack>
                                        ) : (
                                            <>
                                                {isValidDate(field, key) ? (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            sx={{ mt: 1.5, mb: 2, width: "100%" }}
                                                            label={key}
                                                            value={dayjs(formData?.[key])}
                                                            onChange={(newValue) => handleDateChange(key, newValue)}
                                                            // renderInput={(params:any) => (
                                                            //     <TextField
                                                            //         {...params}
                                                            //         variant="standard"
                                                            //         fullWidth
                                                            //     />
                                                            // )}
                                                        />
                                                    </LocalizationProvider>
                                                ) : autocompleteOptions?.[getKey(key)] ? (
                                                    <Autocomplete
                                                        freeSolo
                                                        fullWidth
                                                        options={autocompleteOptions?.[getKey(key)]?.options || []}
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={key}
                                                                variant="standard"
                                                                error={false}
                                                                helperText=""
                                                            />
                                                        )}
                                                        value={typeof formData[key] === "object" ? "" : formData[key]}
                                                        onChange={(_, newValue) => {
                                                            if ((key === 'job_type' || key === 'job_type_id') && onJobTypeChange) {
                                                                onJobTypeChange(newValue);
                                                            }
                                                            setFormData((prevState: any) => ({
                                                                ...prevState,
                                                                [key]: newValue || "", // Handle single value
                                                            }));
                                                        }}
                                                    />
                                                ) : (
                                                    <TextField
                                                        label={key}
                                                        name={key}
                                                        value={formData?.[key]}
                                                        onChange={handleChange}
                                                        variant="standard"
                                                        fullWidth
                                                        disabled={excludedFields.includes(key)}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                ))}
                        </Grid>
    
                        {/* Lower container for objects */}
                        <Grid container sx={{ width: "100%", height: "auto", padding: "1rem", display: "flex", justifyContent: "start", alignItems: "stretch" }} gap={1}>
                            {Object.entries(headerFields)
                                .filter(([key, field]) => !excludedFields.includes(key) && typeof field === 'object' && field !== null)
                                .map(([key, field]: any, index) => (
                                    <Grid item key={index} lg={2} md={4} sm={6} xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "stretch" }}>
                                        {!editMode ? (
                                           <Stack direction="column" justifyContent="start" alignItems="start">
                                            <Typography variant="body1" sx={{ textDecoration: "underline", fontWeight: 600 }}>
                                                {`${key?.split("_")[0]} ${key?.split("_")[1] || ''}`}
                                            </Typography>
                                        
                                            {Array.isArray(field) ? (
                                                // If field is an array
                                                <Grid container direction="column" sx={{ display: "flex", justifyContent: "start", alignItems: "stretch" }}>
                                                    {field
                                                        .filter((_, index) => !excludedFields.includes(index.toString())) // Filter based on index for arrays
                                                        .map((item, index) => (
                                                            <Stack key={index} sx={{ display: "flex", justifyContent: "start", alignItems: "stretch" }}>
                                                         
                                                                <Typography variant="body2" sx={{ color: theme.palette.primary.main, fontWeight: 600, marginLeft: 0.5 }}>
                                                                    {item}
                                                                </Typography>
                                                            </Stack>
                                                        ))}
                                                </Grid>
                                            ) : (
                                                // If field is an object
                                                <Grid container direction="column" sx={{ display: "flex", justifyContent: "start", alignItems: "stretch" }}>
                                                    {Object.entries(field)
                                                        .filter(([innerKey]) => !excludedFields.includes(innerKey))
                                                        .map(([innerKey, innerValue], innerIndex) => (
                                                            <Stack direction="row" key={innerIndex} sx={{ display: "flex", justifyContent: "start", alignItems: "stretch" }}>
                                                                <Typography variant="body2" sx={{ color: "grey", fontWeight: 600 }}>
                                                                    {`${innerKey?.replace("_", " ")}: `}
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ color: theme.palette.primary.main, fontWeight: 600, marginLeft: 0.5 }}>
                                                                    {` ${innerValue}`}
                                                                </Typography>
                                                            </Stack>
                                                        ))}
                                                </Grid>
                                            )}
                                        </Stack>
                                       
                                        ) : (
                                            <>
                                                {isValidDate(field, key) ? (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            sx={{ mt: 1.5, mb: 2 }}
                                                            label="Date"
                                                            value={dayjs(formData?.[key])}
                                                            onChange={(newValue) => handleDateChange(key, newValue)}
                                                            // renderInput={(params:any) => (
                                                            //     <TextField
                                                            //         {...params}
                                                            //         variant="standard"
                                                            //         fullWidth
                                                            //     />
                                                            // )}
                                                        />
                                                    </LocalizationProvider>
                                                ) : autocompleteOptions?.[getKey(key)] ? (
                                                    <Autocomplete
                                                        freeSolo
                                                        fullWidth
                                                        options={autocompleteOptions?.[getKey(key)]?.options || []}
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={key}
                                                                variant="standard"
                                                                error={false}
                                                                helperText=""
                                                            />
                                                        )}
                                                        value={formData[key] || ""}
                                                        onChange={(_, newValue) => {
                                                            if ((key === 'job_type' || key === 'job_type_id') && onJobTypeChange) {
                                                                onJobTypeChange(newValue);
                                                            }
                                                            setFormData((prevState: any) => ({
                                                                ...prevState,
                                                                [key]: newValue || "", // Handle single value
                                                            }));
                                                        }}
                                                    />
                                                ) : (
                                                    <TextField
                                                        label={key}
                                                        name={key}
                                                        value={formData?.[key]}
                                                        onChange={handleChange}
                                                        variant="standard"
                                                        fullWidth
                                                        disabled={excludedFields.includes(key)}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                ))}
                        </Grid>
    
                        {/* Edit/Save/Delete Buttons */}
                        <Grid container sx={{ display: "flex", marginTop: "1rem", padding: "1rem", width: "100%" }}>
                            <Grid item xs={6} sx={{ display: "flex" }}>
                                {editable && (
                                    <Grid item>
                                        <CustomButton color="primary" variant="outlined" size="small" onClick={toggleEditMode}>
                                            {editMode ? 'Cancel' : 'Edit'}
                                        </CustomButton>
                                    </Grid>
                                )}
                                {editMode && (
                                    <Grid item sx={{ marginLeft: 1 }}>
                                        <CustomButton color="primary" variant="contained" size="small" onClick={handleSaveClick}>
                                            Save
                                        </CustomButton>
                                    </Grid>
                                )}
                            </Grid>
                            {editMode && (
                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Grid item>
                                        <CustomButton color='error' variant="contained" size="small" onClick={() => confirmDelete()}>
                                            Delete
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
    
                <ConfirmationDialog
                    row={headerFields}
                    open={open}
                    setOpen={setOpen}
                    handleDelete={handleDelete}
                />
            </Grid>
        )
    );
    
};

export default DetailHeader;
