import { AuthenticationDetails, CognitoUserAttribute, CognitoUser, CognitoUserPool, CognitoUserSession, ICognitoUserPoolData, CookieStorage } from "amazon-cognito-identity-js";
import Cookies from 'js-cookie';
import appAPI from "../api/appAPI";

export interface RegisterUserData {
    username: string;
    password: string;
    email: string;
}
const poolData:ICognitoUserPoolData = {
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || "",
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID || "",
    Storage: new CookieStorage({domain: process.env.REACT_APP_API_URL})
};

const userPool = new CognitoUserPool(poolData);

const authService = {
    register: (userData:RegisterUserData) => {
        const { username, password, email } = userData;
        const attributeList: CognitoUserAttribute[] = [];
            attributeList.push(new CognitoUserAttribute({
              Name: 'email',
              Value: email,
            }))
        return new Promise((resolve, reject) => {
            userPool.signUp(username, password, attributeList, [], (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            });
        });
    },

    authenticateUser: (password:string, cognitoUser:CognitoUser, authenticationDetails:AuthenticationDetails) => {
        return new Promise<CognitoUserSession>((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session) => {
                    Cookies.set('sessionToken', session.getIdToken().getJwtToken());
                    resolve(session);
                },
                onFailure: (err) => {
                    reject(err);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    const newPassword = password; // The new password entered by the user

                    delete userAttributes.email_verified;
                    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                        onSuccess: (result) => {
                            resolve(result);
                        },
                        onFailure: (err) => {
                            reject(err);
                        },
                    });
                },
            });
        });
    },

    getCurrentUser: async () => {
        const response = await appAPI.get('/auth/current-user');
        if (!response.data) throw new Error('No current user');
    
        return response.data;
    },
    
    getUserSession: (cognitoUser: CognitoUser) => {
        return new Promise<CognitoUserSession>((resolve, reject) => {
            cognitoUser.getSession((err:any, session:CognitoUserSession) => {
                if (err) {
                    reject(err);
                } else {
                    Cookies.set('sessionToken', session.getIdToken().getJwtToken());
                    resolve(session);
                }
            });
        });
    },

    // Example authService method for changing password
    changePassword: async (cognitoUser:CognitoUser, session:CognitoUserSession, oldPassword: string, newPassword: string): Promise<string> => {
        return new Promise(async (resolve, reject) => {
                if (session && session.isValid()) {
                    cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
                        if (error) {
                            console.log(error)
                            reject(error.message);
                        } else {
                            resolve("Password changed successfully.");
                        }
                    });
                } else {
                    reject('Session is not valid, cannot change password');
                }
            }); 
        }

};

export default authService;
