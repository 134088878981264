import { GridColDef } from "@mui/x-data-grid-premium";
import { useNavigate, useParams } from "react-router-dom";

const sovColumns:GridColDef[] = [
    {
      field: 'job_type_id',
      headerName: 'Job Type ID',
      type: 'string',
      width: 220,
      editable: true,
    },
];

const sovItemColumns:GridColDef[] = [
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'option',
    headerName: 'Option',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    type: 'string',
    width: 120,
    editable: true,
  },
];

const useSovItemColumns = () => {
  const { project_id } = useParams();
  const navigate = useNavigate();

  const handleNavigation = (path:string) => {
    navigate(path);
  };

  return [
    {
      field: 'plan',
      headerName: 'Plan',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'plan_description',
      headerName: 'Plan Description',
      type: 'string',
      width: 160,
      editable: true,
    },
    {
      field: 'elevation',
      headerName: 'Elevation',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'elevation_description',
      headerName: 'Elevation Description',
      type: 'string',
      width: 160,
      editable: true,
    },
    {
      field: 'option',
      headerName: 'Option',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'option_description',
      headerName: 'Option Description',
      type: 'string',
      width: 160,
      editable: true,
    },
    {
      field: 'option_code',
      headerName: 'Option Code',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'number_of_budgets',
      headerName: 'No. Budgets',
      type: 'number',
      width: 120,
      editable: false,
    },
    {
      field: 'budget_id',
      headerName: 'Budget Rev. #',
      type: 'string',
      width: 160,
      editable: false,
      filterable: true,
      autocompletion: false,
      renderCell: (params:any) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation(`/projects/${project_id}/budgets/${params.value}`);
          }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'number_of_proposals',
      headerName: 'No. Proposals',
      type: 'number',
      width: 120,
      editable: false,
    },
    {
      field: 'proposal_id',
      headerName: 'Proposal Rev #',
      type: 'string',
      width: 160,
      editable: false,
      renderCell: (params:any) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation(`/projects/${project_id}/proposals/${params.value}`);
          }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'number_of_contracts',
      headerName: 'No. Contracts',
      type: 'number',
      width: 120,
      editable: false,
    },
    {
      field: 'contract_id',
      headerName: 'Contract Rev #',
      type: 'string',
      width: 160,
      editable: false,
      renderCell: (params:any) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation(`/projects/${project_id}/contracts/${params.value}`);
          }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 120,
      editable: true,
    },   
  ];
};

export default {sovColumns, sovItemColumns, useSovItemColumns};
  