import { GridAlignment, GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";

const useDivisionColumns = (companies:any):GridColDef[] => {
    const columns = useMemo(() => {
        return[
            {
                field: 'company_id',
                headerName: 'Company',
                type: 'string',  
                width: 120,
                filterable: false,
                editable: false,
                headerAlign: 'right' as GridAlignment,
                valueGetter: (params:any) => {
                    return params?.value?.company_number || "";
                }
            },
            {
                field: 'division_number',
                headerName: 'Division',
                type: 'number',
                width: 120,
                filterable: true,
                editable: false,
                headerAlign: 'right' as GridAlignment,
            },
            {
                field: 'division_description',
                headerName: 'Division Description',
                type: 'string',
                width: 300,
                filterable: true,
                editable: true,
            },
            {
                field: 'division_manager',
                headerName: 'Division Manager',
                type: 'string',
                width: 200,
                filterable: true,
                editable: true,
            },
            {
                field: 'division_location',
                headerName: 'Division Location',
                type: 'string',
                width: 200,
                filterable: true,
                editable: true,
            },
            {
                field: 'division_revision_number',
                headerName: 'Revision Number',
                type: 'number',
                width: 150,
                filterable: true,
                editable: true,
            },
            {
                field: 'division_revision_description',
                headerName: 'Revision Description',
                type: 'string',
                width: 300,
                filterable: true,
                editable: true,
            },
            {
                field: 'division_revision_date',
                headerName: 'Revision Date',
                type: 'date',
                width: 180,
                filterable: true,
                editable: true,
                valueGetter: (params:any) => params.value ? new Date(params.value) : undefined,
        
            },
            {
                field: 'division_status',
                headerName: 'Status',
                type: 'string',
                width: 150,
                filterable: true,
                editable: true,
                // Optional: Custom rendering or color based on status
                renderCell: (params:any) => (
                    <span style={{ color: params.value === 'ACTIVE' ? 'green' : params.value === 'INACTIVE' ? 'orange' : 'red' }}>
                        {params.value}
                    </span>
                ),
            },
            {
                field: 'created_at',
                headerName: 'Created At',
                type: 'date',
                width: 180,
                filterable: true,
                editable: false,
                valueGetter: (params:any) => params.value ? new Date(params.value) : undefined,
                valueFormatter: (params: any) => {
                    if (!params.value) {
                    return '';
                    }
                    const date = new Date(params.value);
                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
            {
                field: 'created_by',
                headerName: 'Created By',
                type: 'string',
                width: 150,
                filterable: true,
                editable: false,
            },
            {
                field: 'updated_at',
                headerName: 'Updated At',
                type: 'date',
                width: 180,
                filterable: true,
                editable: false,
                valueGetter: (params:any) => params.value ? new Date(params.value) : undefined,
                valueFormatter: (params: any) => {
                    if (!params.value) {
                    return '';
                    }
                    const date = new Date(params.value);
                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
            {
                field: 'updated_by',
                headerName: 'Updated By',
                type: 'string',
                width: 150,
                filterable: true,
                editable: false,
            }
        ]
    },[companies]);
    return columns;
}

export default useDivisionColumns;
