import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { clearFormErrors } from '../../api/apiSlice';
import { DataGridPremium, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid-premium';
import ObjectID from 'bson-objectid';
import { useParams } from 'react-router-dom';
import { getSovItems } from '../../dropdowns/dropdownSlice';
import { setErrorState } from './stepperSlice';
import { useCreateDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../snackbar/snackbarSlice';
import { apiRouteMap } from '../../routes/urls';

export const requiredFields:any = {
  specifications: ["grouping_type", "specification", "specification_value"],
  'prebuilt-order-items': ["plan"],
  'project-notes': ["item"],
  'flatwork-sfs': ["plan", "elevation", "option"],
  'fnd-concrete-pricings': ["job_code", "builder", "project"],
  'fnd-aggregate-pricings': ["job_code", "builder", "project"],
  'project-checklist': ["checklist_item", "segment_one", "segment_one_description"],
  "locations": ["taxRate", "mapLink", "geolocation"],
  "lots": ["lot_number"],
  "job-types": ["job_type_number"],
  "plan-designs": ["design_type"],
  proposals: ["plan", "elevation", "option"],
  contracts: ["plan", "elevation", "option"],
}

export const jobTypeRequired:any = {
  "specifications": true,
  'project-notes': false,
  'flatwork-sfs': true,
  'prebuilt-orders': true,
}

const PastableGridForm = ({ step, activeStep, completed, handleClose, setCompleted, job_type}:any) => {
  const {selectedItem, message, formErrors, isError, isLoading} = useAppSelector((state:any) => state.stepper);
  const {project_id, budget_id, proposal_id, contract_id} = useParams();
  const [rows, setRows] = useState<any>([]);
  const context = step?.route?.split("/")[1];
  const [numRowsToAdd, setNumRowsToAdd] = useState<number>(1);
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]); 
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const {company_number, division_number} = useAppSelector((state:any) => state.auth);
  const [sovMap, setSovMap] = useState<any>(new Map())
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();

  const [createData] = useCreateDataMutation();

  useEffect(() => {
    const checkPermissions = async () => {
      const queryOpts:any = { name: 'clipboard-read', allowWithoutGesture: false };
      const permissionStatus:any = await navigator.permissions.query(queryOpts);
      if (permissionStatus && permissionStatus.state === 'prompt'){
          navigator.clipboard.readText().catch((error:any) => console.log(error));
      }  
    }
    checkPermissions();
  },[]);

  useEffect(() => { 
    if(job_type){
      const response = dispatch(getSovItems({
        service: "app",
        url: apiRouteMap.get("/sov")?.getItems(project_id)
      }));

      response.then((res:any) => {
        const sovDetail = res.payload.data.find((sov:any) => sov.job_type === job_type);
        const sovId_map = new Map();
        sovDetail && sovDetail?.items?.map((item:any) => {
          sovId_map.set(`${item?.plan}-${item?.elevation}-${item?.option}` ,item?.item_id)
        });
        setSovMap(sovId_map);
      }).catch((error:any) => console.log(error));
    }
  }, [job_type]);

  const addItem = () => {
    const newRow = {
      _id: new ObjectID().toHexString(),
    };
    setRows((currentRows:any) => [...currentRows, newRow]);
  };

  const addMultipleItems = () => {
    for (let i = 0; i < numRowsToAdd; i++) {
      addItem();
    }
  };

  useEffect(() => {
    addMultipleItems();
  },[]);

  const handleEdit = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
 
  const processRowUpdate = useCallback((newRow: any) => {
    setRows((rows:any) => rows.map((row:any) => (row._id === newRow._id) ? newRow : row));
    return newRow;
  }, []);

  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
    console.log(rowModesModel)
  }

  const cancelEditMode = (event:any) => {
    // Iterate through the row selection model
    event.defaultMuiPrevented = true
    Object.keys(rowSelectionModel).forEach((rowId:string) => {
      // Stop the edit mode for each selected row
      console.log(rowSelectionModel, rowId)
      apiRef.current.stopRowEditMode({ id: rowId });
    });
  };
  
  


  const handleSubmit = async (event:any) => {
    try {
      cancelEditMode(event);
      const filtered = rows?.map((row:any) => {
        const key = `${row?.plan}-${row?.elevation}-${row?.option}`.toUpperCase();
        const schedule_of_values_id = sovMap?.get(key);
        return {
          project_id,
          company_number,
          division_number, 
          schedule_of_values_id,
          job_type,
          ...row
      }})?.filter((row:any) => {
        const fields = requiredFields?.[context];
        if(!fields) return false;
        return fields.every((field: string) => {
          const value = row[field];
          //Remove every undefined, null, or fields that are empty
          return value !== undefined && value !== null
        });
      });
      
      if(filtered.length === 0){
        dispatch(setErrorState(true));
        dispatch(showSnackbar({message: "Submission failed, no data provided.", type: "error"}));
        return;
      }
      
      let id:string;
      switch(step?.idType){
        case 'project_id':
          id = project_id || "";
          break;
        case 'budget_id':
          id = budget_id || selectedItem?._id;
          break;
        case 'proposal_id':
          id = proposal_id || selectedItem?._id;
          break;
        case 'contract_id':
          id = contract_id || selectedItem?._id;
          break;
        default:
          id = project_id || "";
          break;
      }

      const submitBatch = async (batch:any) => {
        const response = await createData({
          url: step?.saveUrl(id),
          body: batch,
          tagName: step?.route
        }).unwrap();
        return response;
      }

      const batchSize = 100;
      for(let i = 0; i < filtered?.length; i += batchSize){
        const batch = filtered?.slice(i, i + batchSize);
        await submitBatch(batch);
      }

      
      dispatch(showSnackbar({ message: 'Data created successfuly', type: 'success' }));
      setCompleted({[activeStep]: true});
      dispatch(clearFormErrors());      
    } catch (error) {
      dispatch(showSnackbar({message: "Batch submission failed, please review the data for errors.", type: "error"}));
    }
  };

  const handlePasteStart = async () => {
    try {
      apiRef.current.selectRows(rows?.map((row:any) => row?._id));
    } catch (error) {
      console.log("Error selecting before clipboard paste")
    }
  }

  const handlePasteEnd = async () => {
    try{
     setRows(rows?.filter((row:any) => {
      if((context in requiredFields)){
        for(const field of requiredFields?.[context]){ 
          if(!(field in row)) return false;
        }
      }
      return true;
    }));

    }catch(error){
      console.log(error)
    }
  }
  
  return (
    <Grid container spacing={2} justifyContent="center" sx={{width: "100%"}}>
      <Grid item xs={12} sx={{padding: 1}}>
        <Box sx={{display: "flex"}}>
          <TextField
          sx={{width: "128px"}}
          label="Number of Rows to Add"
          type="number"
          size="small"
          value={numRowsToAdd}
          onChange={(e) => setNumRowsToAdd(parseInt(e.target.value))}
          />
          <Button sx={{width: "120px", marginLeft: 1}} variant="outlined" onClick={addMultipleItems} component="label">
            Add Rows
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{height: "400px", maxHeight: "400px"}}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(0,0,0,0.25)"
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                document: false,
                created_at: false,
                created_by: false,
                updated_at: false,
                updated_by: false
              },
            },
          }} 
          apiRef={apiRef}
          editMode="row"
          getRowId={row => row?._id}
          density="compact"
          rows={rows}
          columns={step?.column || []}
          loading={isLoading}
          rowHeight={60}
          rowBuffer={10}
          rowModesModel={rowModesModel}
          rowSelectionModel={rowSelectionModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ clipboardPaste: true }}
          onClipboardPasteStart={handlePasteStart}
          onClipboardPasteEnd={handlePasteEnd}
          unstable_ignoreValueFormatterDuringExport
        />
      </Grid>

      <Grid item xs={12} justifyContent="flex-end">
        <Box sx={{display: "flex", justifyContent: "flex-end"}}>

          {!completed[activeStep] &&
          <Button sx={{marginLeft: 1, fontWeight: 600}} variant="contained" onClick={(event:any) => handleSubmit(event)}  component="label">
              Save items
          </Button>}
          {completed[activeStep] &&
          <Button sx={{marginLeft: 1, fontWeight: 600}} variant="contained" onClick={handleClose}  component="label">
              Close
          </Button>}
        </Box>
         
      </Grid>

      {(isError) && 
      <Grid container justifyContent="flex-start" alignItems="center" sx={{mt: 2}} spacing={1}>
        {formErrors ? (
          <Grid item sm={12} xs={12} sx={{fontSize: 12, color: "red"}}>
            <ul>
              {Object.entries(formErrors).map(([key, value]:any) => (
                  <li key={key}>{value}</li>
              ))}
            </ul>
          </Grid>
        ) : (
          <Grid item sm={12} xs={12} sx={{fontSize: 12, color: "red"}}>
              {message}
          </Grid>
        ) }
      </Grid>}
    </Grid>
  );
};

export default PastableGridForm;