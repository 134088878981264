import { useParams } from "react-router-dom";
import projectInformationColumns from "../../components/columns/ProjectInformation";
import flatworkSfColumns from "../../components/columns/flatworkSfColumns";
import LotCols from "../../components/columns/lotColumns";
import PlpColumns from "../../components/columns/plpColumns";
import Steps from "../../components/stepper/Steps";
import { SubjobColumns } from "../../components/columns/SubjobColumns";
import ProjectDetailCols from "./ProjectDetailCols";
import checklistColumns from "../../components/columns/projectChecklistColumns";
import { ProjectPlanDesignColumns } from "../../components/columns/projectPlanDesignColumns";
import { useMemo } from "react";
import specificationColumns from "../../components/columns/specificationColumns";
import { BudgetColumns } from "../../components/columns/fndBudgetColumns";
import ProposalCols from "../../components/columns/ProposalCols";
import ContractCols from "../../components/columns/ContractCols";

interface TabContextItem {
  id: string;
  index: number;
  label: string;
  isClickable: boolean;
  deletable: boolean;
  getUrl: string;
  ref: string;
  grouped: boolean;
  steps: any;  // Replace `any` with the actual type for Steps if available
  grouping: string[];
  columns: any;  // Replace `any` with the actual type for columns if available
}

export const tabStyles = [
  { tabIndex: 0, borderColor: 'rgba(76, 175, 80, 0.9)' },
  { tabIndex: 1, borderColor: 'rgba(76, 175, 80, 0.9)' },
  { tabIndex: 2, borderColor: 'rgba(76, 175, 80, 0.9)' },
  { tabIndex: 3, borderColor: 'rgba(76, 175, 80, 0.9)' },
  { tabIndex: 4, borderColor: 'rgba(229, 57, 53, 0.9)' },
  { tabIndex: 5, borderColor: 'rgba(229, 57, 53, 0.9)' },
  { tabIndex: 6, borderColor: 'rgba(229, 57, 53, 0.9)' },
  { tabIndex: 7, borderColor: 'rgba(33, 33, 33, 0.9)' },
  { tabIndex: 8, borderColor: 'rgba(33, 33, 33, 0.9)' },
  { tabIndex: 9, borderColor: 'rgba(33, 33, 33, 0.9)' },
  { tabIndex: 10, borderColor: 'rgba(33, 33, 33, 0.9)' },
  { tabIndex: 11, borderColor: 'rgba(158, 158, 158, 0.9)' },
]


const useProjectTabs = () => {
  const tabContext = useMemo(() => [
    {
      id: '/project-notes',
      index: 0,
      label: 'Project Notes',
      isClickable: false,
      deletable: true,
      getUrl: 'project-notes',
      ref: 'data',
      grouped: false,
      grouping: [],
      steps: Steps?.projectInformationCreation,
      columns: projectInformationColumns,
    },
    {
      id: '/plan-designs',
      index: 1,
      label: 'Plan Design',
      isClickable: false,
      deletable: true,
      getUrl: 'plan-designs',
      ref: 'data',
      grouped: false,
      grouping: [],
      steps: Steps?.projectPlanDesignCreation,
      columns: ProjectPlanDesignColumns,
    },
    {
      id: '/specifications',
      index: 2,
      label: 'Specifications',
      isClickable: false,
      deletable: true,
      getUrl: 'specifications',
      ref: 'data',
      grouped: false,
      steps: Steps?.specCreation,
      grouping: ['job_type'],
      columns: specificationColumns?.stepTwo,
    },
    {
      id: '/project-checklist',
      index: 3,
      label: 'Checklist',
      isClickable: false,
      deletable: true,
      getUrl: 'project-checklist',
      ref: 'data',
      grouped: false,
      steps: Steps?.checklistCreation,
      grouping: ['job_type'],
      columns: checklistColumns,
    },
    {
      id: '/budgets',
      index: 4,
      label: 'Budgets',
      tabColor: "red",
      isClickable: true,
      deletable: false,
      getUrl: 'fnd-budgets',
      ref: 'budget_items',
      grouped: true,
      steps: Steps?.budgetCreation,
      grouping: ['job_type'],
      columns: BudgetColumns,
    },
    {
      id: '/proposals',
      index: 5,
      label: 'Proposals',
      tabColor: "red",
      isClickable: true,
      deletable: false,
      getUrl: 'proposals/items/all',
      ref: 'items',
      grouped: true,
      steps: Steps?.proposalCreation,
      grouping: ['job_type'],
      columns: ProposalCols?.ProposalColumns,
    },
    {
      id: '/contracts',
      index: 6,
      label: 'Contracts',
      tabColor: "red",
      isClickable: true,
      deletable: false,
      getUrl: 'contracts/items/all',
      ref: 'items',
      grouped: true,
      steps: Steps?.contractCreation,
      grouping: ['job_type'],
      columns: ContractCols?.ContractColumns,
    },
    {
      id: '/prebuilt-orders',
      index: 7,
      label: 'Pre-Built Orders',
      isClickable: false,
      deletable: false,
      getUrl: '/prebuilt-orders',
      ref: 'data',
      grouped: true,
      steps: Steps?.createPlps,
      grouping: ['job_type'],
      columns: PlpColumns,
    },
    {
      id: '/flatwork-sfs',
      index: 8,
      label: 'Flatwork SF',
      isClickable: false,
      deletable: true,
      getUrl: 'flatwork-sfs',
      ref: 'data',
      grouped: false,
      steps: Steps?.createFlatworkSF,
      grouping: ['plan', 'elevation', 'option'],
      columns: flatworkSfColumns,
    },
    {
      id: '/lots',
      index: 9,
      label: 'Lots',
      isClickable: false,
      deletable: true,
      getUrl: 'lots',
      ref: 'starts',
      grouped: true,
      grouping: [],
      steps: Steps?.lotCreation,
      columns: LotCols?.LotColumns,
    },
    {
      id: '/starts',
      index: 10,
      label: 'Subjobs',
      isClickable: true,
      deletable: false,
      getUrl: 'starts',
      ref: 'data',
      grouped: false,
      steps: Steps?.requestStart,
      grouping: ['job_type'],
      columns: SubjobColumns,
    },
    {
      id: '/documents',
      index: 11,
      label: 'Documents',
      isClickable: false,
      deletable: true,
      grouping: [],
      getUrl: "documents",
      ref: "data",
      grouped: false,
      steps: {},
      columns: []
    },
  ], []);  // The empty dependency array ensures tabContext is only created once.

  return tabContext;
};

export default useProjectTabs;
