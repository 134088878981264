import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, IconButton, Tab, Tabs } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation, useParams } from "react-router-dom";
import { DataGridPremium, GridActionsCellItem, GridCellParams, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel, GridRowParams } from "@mui/x-data-grid-premium";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import PanelContent from "./PanelContent";
import { setSelectedItem } from "../../api/apiSlice";
import styles from './detail.module.css';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import DocumentView from "../document-viewer/DocumentView";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { useGetDataQuery, useUpdateDataMutation, useDeleteDataMutation } from "../../api/apiSliceV2";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { showSnackbar } from "../snackbar/snackbarSlice";
import { apiRouteMap } from "../../routes/urls";
import StartHeader from "../../starts/StartIHeader";

interface DetailViewProps {
  tabs: any;
}

const DetailView: React.FC<DetailViewProps> = ({ tabs }) => {
  const { isAuthenticated, company, division } = useAppSelector((state: any) => state.auth);
  const { selectedItem } = useAppSelector((state: any) => state.api);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const routeSegment = `/${location.pathname.split("/")[3]}`;
  const { start_id, budget_id, contract_id, proposal_id } = useParams();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<any>([]);
  const [currentColumns, setCurrentColumns] = useState<any[]>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
  const [dialog, openDialog] = useState(false);
  const [editModes, setEditModes] = useState(new Map());
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [context, setContext] = useState<any>({});
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [tabsHeight, setTabsHeight] = useState(0);
  const [outerHeight, setOuterHeight] = useState<string>("");
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);

  const detailParams = useMemo(() => {
    console.log(context)
  return { 
    url: `/starts/details`, 
    params: { id: start_id }, 
    tagName: `${context?.route}/${start_id}` 
  }}, [context, apiRouteMap, start_id]);
  const {data: detail} = useGetDataQuery(detailParams);

  useEffect(() => {
    if(detail) dispatch(setSelectedItem(detail));
  },[detail, dispatch]);

  const queryParams = useMemo(() => ({ 
    url: context?.getUrl, 
    params: { start_id }, 
    tagName: context?.route 
  }), [context, start_id]);
  const {data: response,  isSuccess, isLoading, isError, error, status} = useGetDataQuery(queryParams);
  const [updateData] = useUpdateDataMutation();
  const [deleteData] = useDeleteDataMutation();

  useEffect(() => {
    if (isError && Error && 'data' in Error) {
      const errorMessage = `Error Status ${status}: ${JSON.stringify(Error.data) || 'Failed to request order'}`;
      dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
    } else if (isSuccess && response) {
      dispatch(showSnackbar({ message: response?.message || 'Operation successful', type: 'success' }));
    }
  }, [isError, Error, status, isSuccess, response, dispatch]);

 
  const handleTabChange = (event: any, newValue: number) => {
    setCurrentTabIndex(newValue);
    setDetailPanelExpandedRowIds([]);
  };

  // Reset view context 
  useEffect(() => {
    const c: any = tabs?.find((c: any) => c?.index === currentTabIndex);
    setContext(c);

    return () => {
      setContext({});
      setRows([]);
    }
  },[currentTabIndex]);

  // Measure the height of the Tabs component after it renders
  useEffect(() => {
    if (tabsRef?.current) {
      setTabsHeight(tabsRef.current.offsetHeight || 0);
    }

    const handleResize = () => {
      setViewportHeight(window?.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [viewportHeight]);

  // Update outer height based on viewport height and tabs height
  useEffect(() => {
    setOuterHeight(`calc(${viewportHeight}px - 2vh - ${tabsHeight}px - 6.5rem)`);
  }, [viewportHeight, tabsHeight]);

  const toggleHeaderVisibility = () => {
    setIsHeaderVisible((current:boolean) => !current);
  }

  const editColumn = {
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 30,
    cellClassName: 'actions',
    renderCell: (params: GridRowParams) => {
      const { id } = params;
  
      return (
        <GridActionsCellItem
          key={id}
          icon={<DeleteForeverSharpIcon />}
          label="Delete"
          className="textPrimary"
          onClick={() => handleDelete(id)}
          color="primary"
        />
      );
    }
  };

  useEffect(() => {
    setCurrentColumns(context?.deletable ? [editColumn, ...(context?.columns || [])] : (context?.columns || []));
  }, [context]);

  const handleDetailPanelExpandedRowIdsChange = (newExpandedRowIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newExpandedRowIds.length > 1 ? [newExpandedRowIds[newExpandedRowIds.length - 1]] : newExpandedRowIds);
  };

  const getDetailPanelContent = React.useCallback(({ row }: any) => {
    if (!context?.expandable) return;
    const rowData = response?.data?.find((r: any) => r._id === row._id);
    const panelColumns = context?.panelColumns;
    const items = rowData?.startItems || rowData?.order_items || [];

    return (
      <PanelContent
        company_number={selectedItem?.company_number}
        job_number={selectedItem?.job_number}
        row={row}
        routeSegment={context?.route}
        columns={panelColumns}
        items={items}
        currentTabIndex={currentTabIndex}
        toolbarComponent={context?.panelToolbar}
      />
    )
  }, [selectedItem, response, context, currentTabIndex]);

  const getDetailPanelHeight = React.useCallback<NonNullable<DataGridProProps['getDetailPanelHeight']>>(() => 'auto' as const, []);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleDelete = useCallback(async (id: GridRowId) => {
    const url = apiRouteMap.get(context?.route).delete(id, start_id);
    await deleteData({
      tagName: context?.route,
      url 
    });
  },[deleteData,  context, apiRouteMap]);

  const handleDeleteAll = async () => {
    try {
      const ids = response?.data?.map((row: any) => row?._id || row?.id);
      await deleteData({
        ids,
        url: apiRouteMap.get(context?.route).url,
        tagName: context?.route
      });
      dispatch(showSnackbar({ message: `Documents deleted`, type: 'success' }));
    } catch (error) {
      console.log("Error deleting data: ", error)
    }
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setEditModes(new Map(editModes.set(id, false)));
    openDialog(false);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row._id === id);
    if (editedRow && editedRow!.isNew) {
      setRows(rows.filter((row: any) => row._id !== id));
    }
  };

  const processRowUpdate = useCallback(async (newRow: any) => {
    try {
      const response = await updateData({
        tagName: context?.route, 
        body: newRow,
        url: apiRouteMap.get(context?.route).update(newRow?._id)
      }).unwrap();
      dispatch(showSnackbar({ message: response?.message, type: 'success' }));
      return newRow;

    } catch (error:any) {
      dispatch(showSnackbar({ message: error?.data?.message || error?.message, type: 'error' }));
    }
 

  }, [updateData, context, apiRouteMap]);

  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
  }


  return (
    <Grid container justifyContent="center" alignItems="flex-end" className={styles.outer}>

    {context?.header &&
      <StartHeader
        selectedItem={selectedItem || {}}
        editable={true}
        isHeaderVisible={isHeaderVisible}
      />}

    <Grid item xs={12} sx={{ justifyContent: 'flex-start', display: 'flex' }}>
      <IconButton size="large" color="primary" onClick={toggleHeaderVisibility}>
        {isHeaderVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Grid>

    {/* Tabs */}
    <Grid item xs={12} justifyContent="start" sx={{height: "auto"}}>
      <Tabs
        ref={tabsRef}
        value={currentTabIndex}
        defaultValue={0}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabs?.map((tab: any, index: number) => (
          <Tab key={index} sx={{ fontWeight: 600 }} label={tab?.label || "Default Label"} value={tab.index} />
        ))}
      </Tabs>
    </Grid>

    {/* Conditional Content */}
    {context?.tabWithFS ? (
      <Grid item justifyContent="center" alignItems="flex-start" sx={{ height: "800px", width: "100%" }}>
        <DocumentView context={context} routeSegment={routeSegment} />
      </Grid>
    ) : (
      <Grid item sm={12} xs={12} sx={{ flexGrow: 1, height: '100%', width: '100%' }}>
        <DataGridPremium
          sx={{
            fontSize: 14,
            fontWeight: 500,
            border: "1px solid rgba(255,255,255,0.5)",
            maxHeight: outerHeight,
            flexGrow: 1,
            '& .MuiDataGrid-columnHeader': {
              whiteSpace: 'normal',
              lineHeight: '1.5',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              overflow: 'visible',
              whiteSpace: 'normal',
              lineHeight: '1',
              wordWrap: 'break-word',
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'lot_number', sort: 'asc' }],
            },
            columns: {
              columnVisibilityModel: {
                //company_id: false,
                //division_id: false,
                customer_id: false,
                job_types: false
              },
            },
          }}
          key={context}
          editMode="row"
          getRowId={(row: any) => row?._id || row?.id || row?.order_id}
          density="compact"
          rows={response?.data || []}
          columns={currentColumns || []}
          loading={isLoading}
          rowHeight={60}
          rowBuffer={10}
          rowModesModel={rowModesModel}
          rowReordering
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error: any) => console.log(error)}
          slots={{
            toolbar: context?.toolbar,
            detailPanelExpandIcon: KeyboardArrowDownSharpIcon,
            detailPanelCollapseIcon: KeyboardArrowUpSharpIcon,
          }}
          slotProps={{ toolbar: { 
            columns: currentColumns,
            context,
            isUpdateMode: true,
            ...(context?.deletable ? { handleDeleteAll } : {})
          } }}
          getDetailPanelHeight={context?.expandable ? getDetailPanelHeight : undefined}
          getDetailPanelContent={context?.expandable ? getDetailPanelContent : undefined}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            return params.isEditable ? `${styles.editableCell}` : '';
          }}
          getRowClassName={(params) =>
            detailPanelExpandedRowIds.includes(params.id) ? `${styles.highlighted}` : ''
          }
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        />
      </Grid>
    )}
    </Grid>
  );
}

export default DetailView;
